import React, { useEffect, useState } from "react";
import taxi from "../img/taxi-solid.png";
import eye from "../img/eye-solid.png";
import map from "../img/map-marker-alt-solid.png";
import user from "../img/user-solid.png";
import bed from "../img/bed-solid.png";
import Tailormade from "../img/Tailormade.png";

import banner from "../img/VIP2.jpg";

import boat from "../img/sqr_banner10.jpg";
import sigiriya from "../img/sqr_banner2.jpg";
import elephants from "../img/sqr_banner11.jpg";
import _boat from "../img/sqr_banner8.jpg";
import sigiriya_02 from "../img/sqr_banner1.jpg";
import polonaruwa from "../img/sqr_banner6.jpg";
import Popular from "../components/Popular";
import { Link } from "react-router-dom";
import axios from "axios";


const AllVip = () => {
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

   const [data, setData] = useState([]);
   const [types, setTypes] = useState("VIP");
   const [category, setCategory] = useState("");
   const [error, setError] = useState("");

   const url = data.map((link) =>
     link.heading.toLowerCase().replace(/ /g, "-")
   );

   console.log(url);



   const getData = async () => {
     try {
       const response = await axios.get(`/get_all_tours`, {
         params: { types: types, category: category },
         headers: {
           "Content-Type": "application/json",
         },
       });
       setData(response.data);
       setError(""); // Clear any previous errors
     } catch (error) {
       if (error.response && error.response.status === 404) {
         setError("No matching tours found. Try something else.");
       } else {
         setError("An error occurred. Please try again later.");
       }
       setData([]); // Clear data if there's an error
     }
   };

   useEffect(() => {
     getData();
   }, [category, types]);

  return (
    <>
      <div
        className="TourBanner_VIP"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          objectFit: "contain",
        }}
      ></div>
      <div className="sub_branch">
        <div className="subBranch">
          <ul className="breadcrumb_2">
            <li>
              <Link to={"/"}>
                <img
                  className="icon3"
                  src={require("../img/home-solid.png")}
                  alt="seylontour"
                />
              </Link>
            </li>

            <li>
              <Link to={"/sri-lanka-tour-package"}>Tours</Link>
            </li>

            <li>VIP Tours Packages</li>
          </ul>
        </div>
      </div>

      <div className="TourContainer">
        <div className="TCone">
          <h3>Be our special guest</h3>
          <br />
          <p>
            {" "}
            <samp style={{ fontSize: "30px" }}>G</samp>
            et the VIP treatment and explore Sri Lanka. Customize the itinerary
            to suit your jet set lifestyle, sip a world's best ceylon tea in the
            hill side and much more. <br />
            <br />
            Our VIP Tour programme has been designed to offer an outstanding and
            unforgettable touring experience in Sri Lanka. The best hotels,
            attractions, restaurants, Transport (air taxi service), Guiding,
            scenery and cities have been combined to give you the time of your
            life. With our unparalleled quality of service and comprehensive
            knowledge of the country we will be able to help you make the most
            of your time and your tour run as smoothly as possible. In addition
            to our tour packages we can enhance your stay with a number of
            fantastic experiences or help you control your business whilst on
            vacation.
          </p>
          <div className="ExperiencesCon">
            <div className="Experiences">
              <h2>
                {" "}
                <span> Experiences and Expertise</span>
              </h2>
              <br />
              <ul>
                <li>
                  <c>1</c>
                  First className and Business className road transport with Sea
                  Plane and helicopter transfers.
                </li>
                <br />
                <li>
                  <c>2</c>
                  Air Taxi Service The experience of flying with our luxurious
                  air taxi in Sri Lanka affords passengers a bird’s-eye-view of
                  the stunning scenery below, whilst significantly reducing
                  surface travel time to destinations across the country.
                </li>
                <br />
                <li>
                  <c>3</c>
                  Our exclusive guiding services will allow you to have private
                  access to some attractions.
                </li>
                <br />
                <li>
                  <c>4</c>
                  Attention to detail and service is unrivalled - timed
                  itinerary.
                </li>
                <br />
                <li>
                  <c>5</c>
                  Hot Air Ballooning over cultural triangle & Unique 4&4
                  Wildlife Jeep Safari in ASIA
                </li>
                <br />
                <li>
                  <c>6</c>Our complete packages to include everything you will
                  need.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="TCtwo">
          <h2>VIP PACKAGES</h2>
          <br />

          <div className="allTour">
            {error && <p>{error}</p>}
            {!error && data.length > 0
              ? data.map((item, i) => (
                  <Link
                    onClick={handleScroll}
                    to={`/sri-lanka-tour-package/${item.heading
                      .toLowerCase()
                      .replace(/ /g, "-")}/${item._id}`}
                    key={i}
                  >
                    <div className="tourCard" key={i}>
                      <div className="image">
                        <img
                          style={{ minHeight: "200px" }}
                          src={`${process.env.REACT_APP_TOUR_UPLOAD_LINK}/${item.tourPhotos[0]}`}
                          alt=""
                        />
                      </div>

                      <div className="CSetwo">
                        <h2>{item.heading}</h2>
                        <span>{item.subHeading}</span>
                        <div className="icons">
                          <img src={taxi} alt="Transport Include" />
                          <img src={eye} alt="Sightseeing" />
                          <img src={map} alt="Locations" />
                          <img src={user} alt="English Speaking Chauffeur" />
                          <img src={bed} alt="Hotel Accommodation" />
                          <p>{item.description}</p>
                        </div>
                      </div>
                      <div className="CSeThree">
                        <h3>
                          PRICE ON <br /> REQUEST
                        </h3>
                        <a
                          className="shopButton4"
                          href="{% url 'sri-lanka-5-days-tour' %}"
                        >
                          VIEW TOUR
                        </a>
                        <br />
                        <h4>* * *</h4>
                      </div>
                    </div>
                  </Link>
                ))
              : !error && <p>Loading tours...</p>}
          </div>
        </div>
      </div>
      <Popular />
    </>
  );
};

export default AllVip;
