import React from 'react'
import "./weAreTrusted.scss";

const WeAreTrusted = () => {
  return (
    <div className="weAreTrusted">
      <div className="tour_adv_right">
        <img src={require("../../img/travelite-08.webp")} alt="" />
      </div>
      <div className="tour_adv_left">
        <small className="subTag">We are trusted</small>
        <h1>
          <em className='spFont' >The Wanderer's Guide:</em> <br /> Expert Travel Planning
        </h1>
        <p>
          We specialize in providing bespoke Sri Lankan holiday packages
          tailored made to meet your needs. Our expert team can help you choose
          from the widest range of experiences available in this colorful and
          diverse island. seylontourare your local friends on line to create for
          you the most amazing Sri Lankan experience. <br />
          <br />
          From relaxed tours rich in culture and nature, adventures to the
          beauty of the lush green interior, amazing variety of coastal
          activities or exciting city shopping and night life in Colombo. For a
          short stay, budget getaway, a romantic special time or just adding a
          few days to a business trip. seylontourwill ensure value and reliable
          travel managed services. Sri Lanka is the land of a thousand smiles
          let the first one be seylontour. We look forward to welcoming you
          soon.
        </p>
        {/* <div className="out_line"></div> */}
      </div>
    </div>
  );
}

export default WeAreTrusted
