import React, { useEffect, useState } from 'react'
import {BrowserRouter,Routes,Route} from "react-router-dom"
import Condition from './pages/Condition';
import Faqs from './pages/Faqs';
import ResponsibleTourism from './pages/ResponsibleTourism';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Guidelines from './pages/Guidelines';
import axios from 'axios';
import Footer from './components/Footer';
import WomenTours from './pages/WomenTours';
import SoloTours from './pages/SoloTours';
import AllVip from './pages/AllVip';
import Whatapp from './components/whatapp/Whatapp';
import Error from './pages/Error/Error';
import GroupTour from './pages/group/GroupTour';
import NavBar from './components/navBar/NavBar';
import Home from './pages/home/Home';
import Tours from './pages/tours/Tours';
import NavbarMob from './components/mbileNavBar/NavbarMob';
import Tour from './pages/tour/Tour';
import AutoPopup from './components/autoPopup/AutoPopup';
import Offers from './pages/offers/Offers';
import TourCheckuot from './pages/tourCheckout.jsx/TourCheckuot';
import Contact from './pages/contact/Contact';
import Gallery from './pages/gallery/Gallery';
import About from './pages/about/About';
import TourPage from './pages/tour/TourPage';
import AllTours from './pages/tours/AllTours';
import Blog from './pages/blog/Blog';
import BlogPage from './pages/blog/BlogPage';
import GroupPage from './pages/groupPage/GroupPage';


// axios.defaults.baseURL = "http://localhost:5000/api/v1";
axios.defaults.baseURL = "https://api.leisurery.com/api/v1";
axios.defaults.withCredentials = true;

const App = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 960);
      };

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

  return (
    <BrowserRouter>
      {isMobile ? <NavbarMob /> : <NavBar />}
      <AutoPopup />
      <Whatapp />
      <Routes>
        <Route path="/sri-lanka-tour-package" element={<AllTours />} />
        <Route path="/" element={<Home />} />
        <Route path="/sri-lanka-tour-packages" element={<AllTours />} />
        <Route path="/offers" element={<Offers />} />
        <Route
          path="/sri-lanka-tour-package_new/:tour_name/:_id"
          element={<TourPage />}
        />
        <Route path="/tour-checkout/:data" element={<TourCheckuot />} />
        <Route path="/condition" element={<Condition />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/about" element={<About />} />
        <Route path="/group" element={<GroupPage />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogPage />} />
        <Route path="/sri-lanka-women-tours" element={<WomenTours />} />
        <Route path="/sri-lanka-solo-tours" element={<SoloTours />} />
        <Route path="/sri-lanka-vip-tours" element={<AllVip />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/responsible-tourism" element={<ResponsibleTourism />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/guidelines" element={<Guidelines />} />
        <Route path="/sri-lanka-group-tour" element={<GroupTour />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route
          path="/sri-lanka-tour-package/:tour_name/:_id"
          element={<Tour />}
        />
        <Route exact path="*" element={<Error />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App
