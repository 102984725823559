import React from 'react'

const Tandc = () => {
  return (
    <>
    <div className="InoneD">
    <div className="term_condition1">
        <div className="include">
            <ul className="include_li">
                <h2>
                    Include
                </h2>
                <li>
                    <c>1</c> Private Tour Package (100%
                    Tailor-made)
                </li>
                <li>
                    <c>2</c>
                    Transport in an Air-Conditioned Vehicle with an accompanying
                    English speaking Chauffeur Guide
                </li>
                <li>
                    <c>3</c>
                    Daily Breakfast or Dinner, Lunch as Specified above 5 days accommodation
                </li>
                <li>
                    <c>4</c> Double, Twin or Triple Rooms
                    as required by the traveler
                </li>
                <li>
                    <c>5</c> All Government taxes and
                    service charges
                </li>
                <li>
                    <c>6</c> All Parking, taxes and fuel
                    charges etc.
                </li>
                <li>
                    <c>7</c> Early/Late check-in or check-out
                </li>
                <li>
                    <c>8</c>
                    Entrance/excursion fees to the places mentioned in our itinerary *
                </li>
            </ul>
            <br />
            <ul className="not_include_li">
                <h2>
                    What’s not include
                </h2>
                <li>
                    <c>1</c>
                    Tips and other expenses of a personal nature
                </li>
                <li>
                    <c>2</c> Visa and travel insurance
                </li>
                <li>
                    <c>3</c>
                    Spending money – budget for meals, snacks, drinks, limited
                    souvenirs, laundry, tips and any extras
                </li>
                <li>
                    <c>4</c>
                    International flights to/from the start/end of your tour and
                    departure taxes
                </li>
            </ul>
        </div>
    </div>


</div>
      
    </>
  )
}

export default Tandc
