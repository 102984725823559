import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const Faqs = () => {
  const [show_1, setShow_1] = useState(false);
  const [show_2, setShow_2] = useState(false);
  const [show_3, setShow_3] = useState(false);
  const [show_4, setShow_4] = useState(false);
  const [show_5, setShow_5] = useState(false);
  const [show_6, setShow_6] = useState(false);
  const [show_7, setShow_7] = useState(false);
  const [show_8, setShow_8] = useState(false);
  const [show_9, setShow_9] = useState(false);
  const [show_10, setShow_10] = useState(false);
  const [show_11, setShow_11] = useState(false);
  const [show_12, setShow_12] = useState(false);
  const [show_13, setShow_13] = useState(false);
  const [show_14, setShow_14] = useState(false);
  const [show_15, setShow_15] = useState(false);
  const [show_16, setShow_16] = useState(false);
  const [show_17, setShow_17] = useState(false);
  const [show_18, setShow_18] = useState(false);
  const [show_19, setShow_19] = useState(false);
  const [show_20, setShow_20] = useState(false);


  const handlShow_1 = () => {
    setShow_1(!show_1);
  };

  const handlShow_2 = () => {
    setShow_2(!show_2);
  };
  const handlShow_3 = () => {
    setShow_3(!show_3);
  };
  const handlShow_4 = () => {
    setShow_4(!show_4);
  };
  const handlShow_5 = () => {
    setShow_5(!show_5);
  };
  const handlShow_6 = () => {
    setShow_6(!show_6);
  };
  const handlShow_7 = () => {
    setShow_7(!show_7);
  };
  const handlShow_8 = () => {
    setShow_8(!show_8);
  };
  const handlShow_9 = () => {
    setShow_9(!show_9);
  };
  const handlShow_10 = () => {
    setShow_10(!show_10);
  };
  const handlShow_11 = () => {
    setShow_11(!show_11);
  };
  const handlShow_12 = () => {
    setShow_12(!show_12);
  };
  const handlShow_13 = () => {
    setShow_13(!show_13);
  };
  const handlShow_14 = () => {
    setShow_14(!show_14);
  };
  const handlShow_15 = () => {
    setShow_15(!show_15);
  };
  const handlShow_16 = () => {
    setShow_16(!show_16);
  };
  const handlShow_17 = () => {
    setShow_17(!show_17);
  };
  const handlShow_18 = () => {
    setShow_18(!show_18);
  };
  const handlShow_19 = () => {
    setShow_19(!show_19);
  };
  const handlShow_20 = () => {
    setShow_20(!show_20);
  };

  return (
    <div className="__fw">
      <div className="__mw">
        <div className="faqs">
          <div className="tours_head">
            <h1>Faqs</h1>
            <br />
            <p>
              Want to get in touch with us? Whether you have a question to ask
              or a problem to solve, <br /> head to our FAQ pages to see if
              we've answered it there.
            </p>
          </div>
          <div className="container_introduction">
            <div className="all_covd">
              <button className="accordion" onClick={handlShow_1}>
                <p>
                  <samp>1</samp> Trip style
                </p>
              </button>
              {show_1 && (
                <div>
                  <p>
                    Trip is tailor-made OR as Fixed tour via Choose among
                    Package
                  </p>
                  <br />
                  <br />
                </div>
              )}

              <button className="accordion" onClick={handlShow_2}>
                <p style={{ color: "rgb(133, 133, 133)" }}>
                  {" "}
                  <samp>2</samp>Room types | Are the hotel rooms Air
                  Conditioned?
                </p>
              </button>
              {show_2 && (
                <div>
                  <p>
                    Depending on the trip style you choose Single, Twin, Triple,
                    Family or Multi-Share basis room.
                    <br />
                    Yes, all holidays that we offer comes with air conditioned
                    (A/C) rooms apart from hotels in Nuwara Eliya and Ella – due
                    to the cool climate in Nuwara Eliya and Ella most hotels do
                    not provide A/C.
                  </p>
                  <br />
                  <br />
                </div>
              )}

              <button className="accordion" onClick={handlShow_3}>
                <p>
                  <samp>3</samp>Travelling with children
                </p>
              </button>
              {show_3 && (
                <div>
                  <p>
                    We offer a wide range of Family Adventures, The minimum age
                    (for Family trips) varies depending on destination or
                    activities suit each age range.
                  </p>
                  <br />
                  <br />
                </div>
              )}

              <button className="accordion" onClick={handlShow_4}>
                <p style={{ color: "rgb(133, 133, 133)" }}>
                  <samp>4</samp>Travelling solo
                </p>
              </button>

              {show_4 && (
                <div>
                  <p>
                    This is fully Personal private trip. Trips have a 50/50
                    balance, and our trips have more included activities. You
                    will have your own room.
                  </p>
                  <br />
                  <br />
                </div>
              )}

              <button className="accordion" onClick={handlShow_5}>
                <p>
                  <samp>5</samp>VIP Tour
                </p>
              </button>

              {show_5 && (
                <div>
                  <p>
                    Get the VIP treatment and explore Sri Lanka.
                    <br />
                    <br />
                  </p>
                </div>
              )}

              <button className="accordion" onClick={handlShow_6}>
                <p style={{ color: "rgb(133, 133, 133)" }}>
                  <samp>6</samp>Women Tours
                </p>
              </button>

              {show_6 && (
                <div>
                  <p>
                    The power to travel the world just gets better with Women’s
                    Special tours! A journey of meeting likeminded women, making
                    new friends and creating new stories.
                  </p>
                  <br />
                  <br />
                </div>
              )}

              <button className="accordion" onClick={handlShow_7}>
                <p>
                  <samp>7</samp>Disability – can I join a trip ?
                </p>
              </button>

              {show_7 && (
                <div>
                  <p>
                    We are committed to making travel widely accessible,
                    regardless of ability or disability. We ask that you
                    carefully consider whether you are physically and mentally
                    able to complete the itinerary you have chosen, we can we
                    will make reasonable adjustments: booking ground floor
                    accommodation, or having our leaders communicate important
                    directions in writing as well as verbally. You can enjoy
                    travelling with a friend or companion who can assist with
                    specific needs.
                  </p>
                  <br />
                  <br />
                </div>
              )}

              <button className="accordion" onClick={handlShow_8}>
                <p style={{ color: "rgb(133, 133, 133)" }}>
                  <samp>8</samp>LGBTQI travellers ?
                </p>
              </button>

              {show_8 && (
                <div>
                  <p>
                    Everyone should feel comfortable when they travel so it’s
                    important for our travellers to be aware of the local laws
                    and customs in the destinations, have laws that discriminate
                    against LGBTQI people.
                    <br />
                    <br />
                  </p>
                </div>
              )}

              <button className="accordion" onClick={handlShow_9}>
                <p>
                  <samp>9</samp>Food & Drink
                </p>
              </button>

              {show_9 && (
                <div>
                  <p>
                    We recommend that when it comes to Sri Lankan food, you stay
                    open-minded and try to be adventurous! Visit USEFUL
                    INFORMATION
                  </p>
                  <br />
                  <br />
                </div>
              )}

              <button className="accordion" onClick={handlShow_9}>
                <p style={{ color: "rgb(133, 133, 133)" }}>
                  <samp>10</samp>Do we get an Air Conditioned vehicle ?
                </p>
              </button>

              {show_10 && (
                <div>
                  <p>
                    Yes - all tourist vehicles are air conditioned (except
                    safari jeeps).
                  </p>
                  <br />
                  <br />
                </div>
              )}

              <button className="accordion" onClick={handlShow_11}>
                <p>
                  <samp>11</samp>How can I make payments ?
                </p>
              </button>

              {show_11 && (
                <div>
                  <p>
                    Payments can be made by Bank Transfer, Online credit card
                    via Payment gateway or Western Union. We accept Visa and
                    MasterCard. As Advance Only 40% of your Full Package Price
                    to confirm the tour & Balance 60% On-arrival need to pay by
                    Cash as per Currency we Mention on Final tour package (All
                    the payment offer a Receipt)
                  </p>
                  <br />
                  <br />
                </div>
              )}

              <button className="accordion" onClick={handlShow_12}>
                <p style={{ color: "rgb(133, 133, 133)" }}>
                  <samp>12</samp>Can I get Internet Access ?
                </p>
              </button>

              {show_12 && (
                <div>
                  <p>
                    Hotels increasingly offer Internet Wi-Fi, and Internet cafes
                    exist in Colombo and many towns, although connection speeds
                    can be Different as per their service Provider.
                  </p>
                  <br />
                  <br />
                </div>
              )}

              <button className="accordion" onClick={handlShow_13}>
                <p>
                  <samp>13</samp>Meeting at the airport in Sri Lanka
                </p>
              </button>
              {show_13 && (
                <div>
                  <p>
                    On arrival you will be met by one of our airport
                    representatives or by your driver/guide. On arrival you will
                    have to look out for your name board (paging board).
                  </p>
                  <br />
                  <br />
                </div>
              )}

              <button className="accordion" onClick={handlShow_14}>
                <p style={{ color: "rgb(133, 133, 133)" }}>
                  <samp>14</samp>Is English spoken in Sri Lanka ?
                </p>
              </button>
              {show_14 && (
                <div>
                  <p>
                    Communication is rarely a problem in Sri Lanka as English is
                    widely spoken in most parts of the country including in
                    hotels, restaurants and even by private transport providers.
                    Signboards of locations are generally available displayed in
                    English, Sinhala & Tamil
                  </p>
                  <br />
                  <br />
                </div>
              )}

              <button className="accordion" onClick={handlShow_15}>
                <p>
                  <samp>15</samp>Credit Cards accepted
                </p>
              </button>

              {show_15 && (
                <div>
                  <p>
                    Hotels and popular shops and restaurants accept most
                    international credit cards. ATM's are available
                  </p>
                  <br />
                  <br />
                </div>
              )}

              <button className="accordion" onClick={handlShow_16}>
                <p style={{ color: "rgb(133, 133, 133)" }}>
                  <samp>16</samp>Medical Facilities
                </p>
              </button>

              {show_16 && (
                <div>
                  <p>
                    Most hotels will have a premise doctor or available nearby
                    on an "on call basis". Please contact the hotel reception
                    for assistance. Pharmacies can easily be found every city,
                    Government & Private hospitals open 24/7
                  </p>
                  <br />
                  <br />
                </div>
              )}

              <button className="accordion" onClick={handlShow_17}>
                <p>
                  <samp>17</samp>Can I change my itinerary when I am in Sri
                  Lanka ?
                </p>
              </button>

              {show_17 && (
                <div>
                  <p>
                    Changes to the itinerary can be made while on tour but in
                    almost all cases the change will incur a surcharge. Amending
                    the flight duration / timings / dates without the relevant
                    hotel booking changes are available at an extra fee.{" "}
                  </p>
                  <br />
                  <br />
                </div>
              )}

              <button className="accordion" onClick={handlShow_18}>
                <p style={{ color: "rgb(133, 133, 133)" }}>
                  <samp>18</samp>What if I have to cancel my holiday ?
                </p>
              </button>
              {show_18 && (
                <div>
                  <p>
                    Please let us know as soon as possible. We expect our
                    customers to take their holiday bookings as seriously as we
                    do. In most cases, it would be possible to postpone your
                    travel dates and reschedule your holiday with little our
                    cancellation charges in our booking conditions apply, at our
                    discretion, in case we cannot recover payments and admin
                    costs committed on your behalf.
                  </p>
                  <br />
                  <br />
                </div>
              )}

              <button className="accordion" onClick={handlShow_19}>
                <p>
                  <samp>19</samp>Is Sri Lanka safe from Terrorism ?
                </p>
              </button>

              {show_19 && (
                <div>
                  <p>
                    Up till May 2009, Sri Lanka had a conflict with the LTTE who
                    wanted a separate state for the Tamils in the North and East
                    of the country, but throughout the conflict, not one single
                    tourist has been injured or killed. As from May 2009, this
                    conflict ended and Sri Lanka is now at peace.
                  </p>
                  <br />
                  <br />
                </div>
              )}

              <button className="accordion" onClick={handlShow_20}>
                <p style={{ color: "rgb(133, 133, 133)" }}>
                  <samp>20</samp>An Emergency
                </p>
              </button>

              {show_20 && (
                <div>
                  <p>
                    We have a 24/7 emergency mobile number to contact our local
                    representatives. Please, note your driver guide will also be
                    with you during the whole tour
                  </p>
                  <br />
                  <br />
                </div>
              )}
              <br />
              <br />
              <p>
                {" "}
                If you have any specific questions on your tour{" "}
                <Link to={"/contact"}>write or Call </Link> to us
              </p>
            </div>

            <div className="info_key">
              <p>
                sri lanka tour packages, sri lanka tour, best tour packages in
                sri lanka, places to visit in sri lanka, sri lanka honeymoon
                packages, sri lanka travel package, sri lanka 5 days itinerary,
                sri lanka tour package, sri lanka trip, sri lanka tour
                itinerary, sri lanka tourism, sri lanka cheap tour packages, sri
                lanka itinerary 4 days, srilanka tour package, 5 day trip to sri
                lanka, 7 days in sri lanka, sri lanka itinerary, sri lanka
                itinerary 1 week, sri lanka itinerary 6 days, sri lanka package
                holidays, sri lanka trip, cheap tour sri lanka, five days in sri
                lanka, holiday in colombo sri lanka, places to visit in sri
                lanka in 5 days, sri lanka holiday, sri lanka tour operators,
                sri lanka travel itinerary, sri lanka trip package, sri lanka
                trip planner, 4 day tour sri lanka, 4 days in sri lanka, 4
                nights 5 days sri lanka package, day tours in sri lanka, sri
                lanka trip packages, sri lanka city tour, sri lanka 7 days tour
                package, sri lanka city tour, sri lanka ground tour package, sri
                lanka holiday itinerary, sri lanka in a week, sri lanka luxury
                tours, sri lanka tour itinerary, sri lanka vacation, srilanka
                tour package itinerary tour package to sri lanka from malaysia,
                sri lanka in 7 days, dynasty travel sri lanka, place to visit in
                sri lanka, sri lanka honeymoon, sri lanka itinerary 7 days, sri
                lanka holidays, 6 day sri lanka tour, sri lanka tour packages
                from malaysia, freelance tour guide in sri lanka, sri lanka tour
                package from singapore, sri lanka 5 day itinerary, tour package
                to sri lanka, honeymoon in sri lanka, sri lanka land tour
                packages, sri lanka honeymoon package, sri lanka travel packages
                from singapore, sri lanka resort tours from singapore, sri lanka
                tour singapore, travel sri lanka, sri lanka tourist attractions,
                sri lanka tour package singapore, srilanka tourism, sri lanka
                tourist attractions, places to visit sri lanka, sri lanka places
                of interest, 5 days in sri lanka, visit sri lanka tours, sri
                lanka package tour from singapore, sri lanka tours from malaysia
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faqs
