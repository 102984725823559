import React from 'react'
import "./topDestinations.scss";

const TopDestinations = () => {
  return (
    <div className="topDestinations">
      <div className="__mw">
        <small className="subTag">top Places</small>
        <h1>
          <em className='spFont' >Where Culture Meets Adventure:</em> <br /> Explore Sri Lanka With Us
        </h1>
        <br />
      </div>
      <div className="imgs">
        <div className="_img">
          <img src={require("../../img/01 2.jpg")} alt="" />
        </div>
        <div className="_img">
          <img src={require("../../img/02 2.jpg")} alt="" />
        </div>
        <div className="_img">
          <img src={require("../../img/03 2.jpg")} alt="" />
        </div>
        <div className="_img">
          <img src={require("../../img/04.jpg")} alt="" />
        </div>
      </div>
    </div>
  );
}

export default TopDestinations
