import React from "react";
import "./OurGalleryRight.scss";
import {images} from "./image.js";

const OurGalleryRight = () => {

  return (
    <>
      <div className="img_gal_wrp">
        <div className="img_sec">
          <div className="img_sec_in">
            <img src={`${images[0]}`} alt={`Gallery ${images[0]}`} />
          </div>
          <div className="img_sec_in">
            <img src={`${images[1]}`} alt={`Gallery ${images[1]}`} />
          </div>
        </div>

        <div className="img_sec">
          <div className="img_sec_in">
            <img src={`${images[2]}`} alt={`Gallery ${images[2]}`} />
          </div>
          <div className="img_sec_in">
            <img src={`${images[3]}`} alt={`Gallery ${images[3]}`} />
          </div>
        </div>

        <div className="img_sec_one">
          <img src={`${images[4]}`} alt={`Gallery ${images[4]}`} />
        </div>
        <div className="img_sec_one">
          <img src={`${images[5]}`} alt={`Gallery ${images[5]}`} />
        </div>
        <div className="img_sec">
          <div className="img_sec_in">
            <img src={`${images[6]}`} alt={`Gallery ${images[6]}`} />
          </div>
          <div className="img_sec_in">
            <img src={`${images[7]}`} alt={`Gallery ${images[7]}`} />
          </div>
        </div>
        <div className="img_sec">
          <div className="img_sec_in">
            <img src={`${images[8]}`} alt={`Gallery ${images[8]}`} />
          </div>
          <div className="img_sec_in">
            <img src={`${images[9]}`} alt={`Gallery ${images[9]}`} />
          </div>
        </div>
        <div className="img_sec">
          <div className="img_sec_in">
            <img src={`${images[15]}`} alt={`Gallery ${images[15]}`} />
          </div>
          <div className="img_sec_in">
            <img src={`${images[18]}`} alt={`Gallery ${images[18]}`} />
          </div>
        </div>

        <div className="img_sec">
          <div className="img_sec_in">
            <img src={`${images[19]}`} alt={`Gallery ${images[19]}`} />
          </div>
          <div className="img_sec_in">
            <img src={`${images[16]}`} alt={`Gallery ${images[16]}`} />
          </div>
        </div>

        <div className="img_sec_one">
          <img src={`${images[17]}`} alt={`Gallery ${images[17]}`} />
        </div>
      </div>
    </>
  );
};

export default OurGalleryRight;

