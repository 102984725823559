import axios from "axios";
import React, { useEffect, useState } from "react";
import { MdOutlineStar } from "react-icons/md";
import { Link } from "react-router-dom";
import "../../pages/tours/allTours.scss";

const SelectTours = () => {
  const [data, setData] = useState([]);
  const [types, setTypes] = useState("");
  const [category, setCategory] = useState("");
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  const onEditClick = () => {
    window.scrollTo(0, 0);
  };
  const handleOpen = () => {
    setShow(!show);
  };

  const getData = async () => {
    try {
      const response = await axios.get(`/get_all_tours`, {
        params: { types, category },
        headers: {
          "Content-Type": "application/json",
        },
      });
      const limitedData = response.data.slice(0, 3);
      setData(limitedData);
      setError(""); // Clear any previous errors
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError("No matching tours found. Try something else.");
      } else {
        setError("An error occurred. Please try again later.");
      }
      setData([]); // Clear data if there's an error
    }
  };

  useEffect(() => {
    getData();
  }, [category, types]);
  return (
    <>
      <div className="tours_wrp">
        {data.map((item, i) => (
          <div className="_tourCard" key={i}>
            <div className="image">
              <Link
                onClick={handleScroll}
                to={`/sri-lanka-tour-package_new/${item.heading
                  .toLowerCase()
                  .replace(/ /g, "-")}/${item._id}`}
              >
                <img
                  style={{ minHeight: "200px" }}
                  src={`${process.env.REACT_APP_TOUR_UPLOAD_LINK}/${item.tourPhotos[0]}`}
                  alt=""
                />
              </Link>
            </div>
            <div className="_info">
              <div className="info_date">
                <span>
                  {item.category} & {parseInt(item.category) - 1} Nights
                </span>
                <strong>
                  <MdOutlineStar />
                </strong>
              </div>
              <h3>{item.heading}</h3>
              <div className="type_wrp">
                <span>{item.types} </span>
              </div>
              {item.discount ? (
                <div className="offer_amount_wrp">
                  <span>{item.discount}% OFF</span>
                </div>
              ) : (
                <div className="offer_amount_wrp">
                  <span>Dinner Free</span>
                </div>
              )}

              {item.price ? (
                <div className="price_tour">
                  <span>
                    $ {parseFloat(item.price).toFixed(2)} <em>P/P</em>{" "}
                  </span>
                  <small>${parseFloat(item.price * 1.1).toFixed(2)}</small>
                  <em>
                    SAVE ${parseFloat(item.price * 1.1 - item.price).toFixed(2)}
                  </em>
                </div>
              ) : (
                <div className="price_tour">
                  <span>Price on Request </span>
                </div>
              )}

              <div className="contact_wrp">
                <a href="https://wa.me/94778302226" target="_blank">
                  <img src={require("../../img/whap.png")} alt="" />
                </a>

                <div onClick={handleOpen} className="_contact_wrp_cal">
                  <Link
                    onClick={handleScroll}
                    to={`/sri-lanka-tour-package_new/${item.heading
                      .toLowerCase()
                      .replace(/ /g, "-")}/${item._id}`}
                  >
                    <span style={{color:"white",fontSize:"14px"}}>View Full Tour Guide</span>
                  </Link>
                </div>
              </div>

            </div>
          </div>
        ))}
      </div>

    </>
  );
};

export default SelectTours;
