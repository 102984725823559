import axios from "axios";
import React, { useState } from "react";

const SideInfo = () => {
  

  const [show_1, setShow_1] = useState(false);
  const [show_2, setShow_2] = useState(false);
  const [show_3, setShow_3] = useState(false);

  const handlShow_1 = () => {
    setShow_1(!show_1);
  };

  const handlShow_2 = () => {
    setShow_2(!show_2);
  };
  const handlShow_3 = () => {
    setShow_3(!show_3);
  };

 

  return (
    <>
      <div class="tourIntwo">
      
        <h2>here some more usefull information for you</h2>
   
        <button class="accordion" onClick={handlShow_1}>
          <div class="acc-img">
            <img
              class="acc-imgs"
              src={require("../img/food.jpg")}
              alt="seylontour"
              style={{ width: "100%" }}
            />
          </div>

          <h3 style={{ color: "#4e8fee" }}>Food </h3>
          <p>
            Sri Lanka is home to a melting pot of cuisine. Throughout years of
            colonization and influence from other countries and a history of
            spice trading,
          </p>
        </button>

        {show_1 && (
          <div>
            <p>
              Sri Lanka is home to a melting pot of cuisine. Throughout years of
              colonization and influence from other countries and a history of
              spice trading, Sri Lankan has adapted its own distinct flavor. So
              it is important to keep an open mind and be adventurous. Dishes
              served some will be vegetable-based, vegetable based with meat
              mixed in, and a few will be solely meat-based. Please be aware
              that dishes selected for your meals reflect the cooking styles and
              signature dishes of the local area you are in. Sri Lankan
              restaurants are well aware of the western palate – there are
              plenty of non-spiced options. Some restaurants Dishes will be
              served in the traditional ‘family style’; meaning that various
              dishes will be laid out in the center of the table. These dishes
              are intended to be shared amongst there  always more than
              enough to feed everybody. We recommend that when it comes to Sri
              Lankan food, you stay open-minded and try to be adventurous!
            </p>
            <br />
            <br />
          </div>
        )}

        <button class="accordion" onClick={handlShow_2}>
          <div class="acc-img">
            <img
              class="acc-imgs"
              src={require("../img/visa.jpg")}
              alt="seylontour"
              style={{ width: "100%" }}
            />
          </div>
          <h3 style={{ color: "#4e8fee" }}>Visas</h3>
        </button>
        {show_2 && (
          <div>
            <p>
              Entry visas are required by all visitors to Sri Lanka, You can
              obtain visa is an ETA (Electronic Travel Authorization), visa
              online http://www.eta.gov.lk/slvisa (Singapore Nationalities
              provide On-arrival Visa) Other Nationalities You will need to keep
              the ETA with your passport and present to authorities upon entry
              into Sri Lanka.
            </p>
            <br />
          </div>
        )}

        <button class="accordion" onClick={handlShow_3}>
          <div class="acc-img">
            <img
              class="acc-imgs"
              src={require("../img/Accommodation.jpg")}
              alt="seylontour"
              style={{ width: "100%" }}
            />
          </div>

          <h3 style={{ color: "#4e8fee" }}>Accommodation</h3>
        </button>
        {show_3 && (
          <div>
            <p>
              All hotels have private western bathroom facilities, air
              conditioning, TV and a telephone. Plumbing and electricity
              supplies can be erratic and quite often the power in hotel rooms
              is turned off while guests are out of the room. If you experience
              any difficulty, please speak to your National / Local Guide. Your
              accommodation has been selected for convenience of location,
              comfort or character, and can range from a business hotel in one
              city to a family-run guesthouse in a smaller town or a
              heritage-type hotel. In remote areas, accommodation may be of a
              lower standard and may not have western amenities. Hotels are
              generally rated as local three to four-star standard, but do
              please note that there is no international classification system
              for hotels and differences in facilities and quality do exist
              between Your Country and Sri Lanka.
            </p>
            <br />
            <br />
          </div>
        )}
      </div>
    </>
  );
};

export default SideInfo;
