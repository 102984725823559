import React, { useState } from "react";
import { Link } from "react-router-dom";

const Condition = () => {
  const [show_1, setShow_1] = useState(false);
  const [show_2, setShow_2] = useState(false);
  const [show_3, setShow_3] = useState(false);
  const [show_4, setShow_4] = useState(false);
  const [show_5, setShow_5] = useState(false);
  const [show_6, setShow_6] = useState(false);
  const [show_7, setShow_7] = useState(false);
  const [show_8, setShow_8] = useState(false);
  const [show_9, setShow_9] = useState(false);
  const [show_10, setShow_10] = useState(false);
  const [show_11, setShow_11] = useState(false);
  const [show_12, setShow_12] = useState(false);
  const [show_13, setShow_13] = useState(false);
  const [show_14, setShow_14] = useState(false);
  const [show_15, setShow_15] = useState(false);
  const [show_16, setShow_16] = useState(false);
  const [show_17, setShow_17] = useState(false);
  const [show_18, setShow_18] = useState(false);
  const [show_19, setShow_19] = useState(false);
  const [show_20, setShow_20] = useState(false);
  const [show_21, setShow_21] = useState(false);
  const [show_22, setShow_22] = useState(false);
  const [show_23, setShow_23] = useState(false);
  const [show_24, setShow_24] = useState(false);

  const handlShow_1 = () => {
    setShow_1(!show_1);
  };

  const handlShow_2 = () => {
    setShow_2(!show_2);
  };
  const handlShow_3 = () => {
    setShow_3(!show_3);
  };
  const handlShow_4 = () => {
    setShow_4(!show_4);
  };
  const handlShow_5 = () => {
    setShow_5(!show_5);
  };
  const handlShow_6 = () => {
    setShow_6(!show_6);
  };
  const handlShow_7 = () => {
    setShow_7(!show_7);
  };
  const handlShow_8 = () => {
    setShow_8(!show_8);
  };
  const handlShow_9 = () => {
    setShow_9(!show_9);
  };
  const handlShow_10 = () => {
    setShow_10(!show_10);
  };
  const handlShow_11 = () => {
    setShow_11(!show_11);
  };
  const handlShow_12 = () => {
    setShow_12(!show_12);
  };
  const handlShow_13 = () => {
    setShow_13(!show_13);
  };
  const handlShow_14 = () => {
    setShow_14(!show_14);
  };
  const handlShow_15 = () => {
    setShow_15(!show_15);
  };
  const handlShow_16 = () => {
    setShow_16(!show_16);
  };
  const handlShow_17 = () => {
    setShow_17(!show_17);
  };
  const handlShow_18 = () => {
    setShow_18(!show_18);
  };
  const handlShow_19 = () => {
    setShow_19(!show_19);
  };
  const handlShow_20 = () => {
    setShow_20(!show_20);
  };
  const handlShow_21 = () => {
    setShow_21(!show_21);
  };
  const handlShow_22 = () => {
    setShow_22(!show_22);
  };
  const handlShow_23 = () => {
    setShow_23(!show_23);
  };
  const handlShow_24 = () => {
    setShow_24(!show_24);
  };

  return (
    <div className="__fw">
      <div className="__mw">
        <div className="conCont">
          <div className="tours_head">
            <h1>Terms And Conditions</h1>
          </div>
          <div className="tandc">
            <div className="tandc_info">
              <p>
                Thank you for choosing Seylon tour for the best travel
                experience. We take all efforts to ensure that you have a warm
                and a delightful holiday experience. We devote keen attention to
                every minute detail of your tour. At the same time, we request
                you to read and follow the below Terms and Conditions. In order
                to use this Website you must give your unqualified consent to
                these terms and conditions and if you disagree with any part of
                these terms and conditions then you may not use this site in any
                way. Therefore, please make sure you read the terms and
                conditions very carefully to avoid any misunderstanding and
                ensure a hassle-free and enjoyable tour.
              </p>
            </div>

            <button className="accordion" onClick={handlShow_1}>
              <p>
                {" "}
                <samp>1</samp> Terms of Use
              </p>
            </button>
            {show_1 && (
              <div>
                <p>
                  The following are terms of a legal agreement between you and
                  Seylon tour. By accessing, browsing, or using this Website,
                  you acknowledge that you have read, understood, and agree to
                  be bound by these terms and to comply with all applicable laws
                  and regulations. If you do not agree to these terms, please do
                  not use this Website. Seylon tour may, without notice to you,
                  at any time revise these Terms of Use and any other
                  information contained in this Website by updating this
                  posting. Seylon tour may also make improvements or changes in
                  the products, services, or programs described in this site at
                  any time without notice. Any use of the
                  https://www.seylontour.com website, or written communication
                  with Seylon tour, for the purposes of travel arrangements,
                  confirms that you are aged 18 or over and that you have read
                  and fully accept these terms and conditions. These terms and
                  conditions are devised for the protection of all parties.
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_2}>
              <p style={{ color: "rgb(133, 133, 133)" }}>
                <samp>2</samp>Confidential Information
              </p>
            </button>
            {show_2 && (
              <div>
                <p>
                  Seylon tour does not want to receive confidential or
                  proprietary information from you through our Web site. Please
                  note that any information or material sent to Seylon tour will
                  be deemed NOT to be confidential. By sending Seylon tour any
                  information or material, you grant Seylon tour an
                  unrestricted, irrevocable license to copy, reproduce, publish,
                  upload, post, transmit, distribute, publicly display, perform,
                  modify, create derivative works from, and otherwise freely
                  use, those materials or information. You also agree that
                  Seylon tour is free to use any ideas, concepts, know-how, or
                  techniques that you send us for any purpose. However, we will
                  not release your name or otherwise publicize the fact that you
                  submitted materials or other information to us unless: "(a)"
                  we obtain your permission to use your name; or "(b)" we first
                  notify you that the materials or other information you submit
                  to a particular part of this site will be published or
                  otherwise used with your name on it; or "(c)" we are required
                  to do so by law. Personally-identifiable information that you
                  submit to Seylon tour for the purpose of receiving products or
                  services will be handled in accordance with our privacy
                  policies. Please see Seylon tour privacy policies.
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_3}>
              <p>
                {" "}
                <samp>3</samp>Translations
              </p>
            </button>
            {show_3 && (
              <div>
                <p>
                  {" "}
                  Certain text in this Web site may be made available in
                  languages other than English. Text may be translated by a
                  person or solely by computer software with no human
                  intervention or review. These translations are provided as a
                  convenience to you, and Seylon tour makes no representations
                  or commitments regarding the accuracy or completeness of the
                  translation, whether or not computer-generated or performed by
                  a person.
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_4}>
              <p style={{ color: "rgb(133, 133, 133)" }}>
                <samp>4</samp>User Agreement
              </p>
            </button>
            {show_4 && (
              <div>
                <p>
                  Access to and use of this website is conditional upon your
                  acceptance of these terms and conditions. Please read the
                  following terms and conditions closely before using our
                  website. If you do not agree with any part of these terms and
                  conditions, you must not use this website. Any use of the
                  https://www.seylontour.com website, or written communication
                  with Seylon tour, for the purposes of travel arrangements,
                  confirms that you are aged 18 or over and that you have read
                  and fully accept these terms and conditions. These terms and
                  conditions are devised for the protection of all parties.
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_5}>
              <p>
                <samp>5</samp>Booking Conditions
              </p>
            </button>
            {show_5 && (
              <div>
                <p>
                  https://www.seylontour.com website is owned and operated by
                  Seylon tour, office located at 87/23A, Kohilakotuwa
                  Road,Neelammahara, Maharagama. Sri Lanka tour packages deals
                  with numerous suppliers in Sri Lanka in offering goods and
                  services through the https://www.seylontour.com website. When
                  goods or services are purchased or reservations are made
                  through our website in addition to the terms and conditions
                  contained herein the individual terms and conditions of these
                  suppliers will also apply. Customers are responsible for
                  reading the supplier’s terms and conditions including booking
                  conditions which are available on request. You agree to abide
                  by the terms or conditions of purchase imposed by any supplier
                  with whom you elect to deal. You understand that any violation
                  of any such supplier’s conditions of purchase may result in
                  cancellation of your reservations or purchases, in your being
                  denied access to any hotels, in your forfeiting any monies
                  paid for such reservations or purchases, and Seylon tour,
                  debiting your account for any costs we incur as a result of
                  such violation. You will be completely responsible for all
                  charges, fees, duties, taxes, and assessments arising out of
                  your use of this website, and, without limitation, you will be
                  responsible for all charges, fees, duties, taxes, and
                  assessments arising out of transactions performed by others on
                  your behalf, whether or not such uses were performed with your
                  consent. Nothing on this website constitutes an offer on our
                  part or on the part of the suppliers. The matters detailed
                  constitute an invitation to you to make an offer on the stated
                  terms to purchase arrangements featured. Our suppliers may
                  accept or decline any such offer. All arrangements featured or
                  referred to are at all times prior to specific confirmation
                  subject to availability and no warranties, promises or
                  representations are given as to availability. With respect to
                  hotel reservations and ground arrangements made directly
                  through this website, your reservation will become confirmed
                  once you have sent an on-line confirmation and provided your
                  credit card details, and paid the total amount due in full OR
                  any required deposits and when we acknowledge receipt of your
                  full payment OR deposit. In some cases an initial
                  non-refundable deposit may be charged.
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_6}>
              <p style={{ color: "rgb(133, 133, 133)" }}>
                <samp>6</samp>Booking Your Holiday
              </p>
            </button>

            {show_6 && (
              <div>
                <p>
                  A booking is accepted only after we receive written
                  confirmation from you along with full payment OR a
                  non-refundable deposit of 20% of the value of the Travel
                  Arrangements." (This may be waived solely at our discretion)".
                  For Peak Season Bookings 50% advance payment of total value is
                  required. If the booking is made within 30 days of arrival in
                  Sri Lanka, then full payment is required at the time of
                  confirmation. Full payment OR the payment of a deposit and
                  your written confirmation will indicate an acceptance of these
                  terms and conditions by you.
                  <h3>Final Payment</h3>
                  <p>
                    The balance of payment due for Your Travel Arrangements if
                    any must be paid by you not later than 30 days before
                    arrival in Sri Lanka; otherwise we may treat the booking as
                    canceled by you.
                  </p>
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_7}>
              <p>
                <samp>7</samp> Methods of Payment
              </p>
            </button>
            {show_7 && (
              <div>
                <p>
                  Payments for deposits and final monies for Travel Arrangements
                  may be made either by
                </p>
                <ul>
                  <li>
                    {" "}
                    <i className="fas fa-angle-double-right"></i>Bank transfer.
                  </li>
                  <li>
                    <i className="fas fa-angle-double-right"></i>Credit card via
                    online "(Visa or Master card - bank service charge 4.5% will
                    be applicable)".
                  </li>
                </ul>
                <p>
                  If paid by bank transfer all additional charges and fees
                  related to this will be borne by you. You can return these
                  forms to us either through email or fax. If paying by credit
                  or debit card through the secure Online payment gateway:- Your
                  payments are processed entirely on the Secure Payment Sites
                  operated by Sampath Bank PLC for Master and Visa card
                  payments. Your card details are not received or stored by
                  Seylon tour in any form, and when you place an order as part
                  of the checkout process you are automatically taken to the
                  Sampath Bank PLC Secure Payment facility for Master and Visa
                  card payments. Your credit card information will be securely
                  transmitted to Sampath Bank PLC for transaction authorization
                  using SSL 128-bit encryption. All payments are denominated in
                  United State dollars "(US$)" SSL, security internet protocol
                  known as Secure Socket Layer, uses connection-oriented
                  end-to-end encryption to provide data confidentiality service
                  and data integrity service for application layer traffic
                  between your web browser and the secured Sampath Bank PLC
                  payment gateway servers. It provides data encryption, server
                  and client authentication and message integrity between the
                  client and the web server. If you fail to meet the payment
                  deadlines stated to you, we reserve the right to cancel your
                  tentative booking and will inform you of this
                </p>
                <ul>
                  <p>For your information :</p>
                  <li>
                    <i className="fas fa-angle-double-right"></i>On arrival the
                    name of the card holder is cross checked against the
                    passport, and also with Booking Details and Credit Card.
                  </li>
                  <li>
                    <i className="fas fa-angle-double-right"></i>If the
                    cardholder is unable to produce the card, transaction need
                    to be reverse back to the card account
                  </li>
                  <li>
                    <i className="fas fa-angle-double-right"></i>Third party
                    travelling should not be entertained, Cardholder must be in
                    travelling party
                  </li>
                  <li>
                    <i className="fas fa-angle-double-right"></i>Air line
                    tickets and destinations out side Sri Lanka should not be
                    entertained.
                  </li>
                </ul>
              </div>
            )}

            <button className="accordion" onClick={handlShow_8}>
              <p style={{ color: "rgb(133, 133, 133)" }}>
                <samp>8</samp>Price Policy / Cancellation & Refund
              </p>
            </button>

            {show_8 && (
              <div>
                <p>
                  We offer our clients customized packages of land and / or air
                  services using various suppliers on request. The price of Your
                  Travel Arrangements is subject to the possibility of changes
                  and surcharges beyond the control of Us and may occur because
                  of governmental action, currency exchange rate fluctuation and
                  increases in suppliers’ prices as an example. If the price of
                  Your Travel Arrangements is increased by any matters outside
                  our control then you must either pay the additional price to
                  us when we request it or cancel Your Travel Arrangements in
                  accordance with these Booking Conditions.
                </p>
                <p>
                  All prices are provided in United States dollars. Amendments
                  and Cancellations <br />
                </p>

                <p>
                  We will use our best endeavors to provide all Travel
                  Arrangements as confirmed, reasonable changes in the Travel
                  Arrangements may be made without notice to You if deemed
                  necessary or advisable by Us at Our discretion.
                </p>
                <p>
                  Cancellation of Your Travel Arrangements by You must be
                  notified to us in writing, and will take effect the day we
                  receive it. The following Penalties will apply dependent upon
                  the number of days before Your Travel Arrangements commence
                </p>
                <ul>
                  <li>
                    <i className="fas fa-angle-double-right"></i>Between 31 and
                    60 days – forfeit of deposit
                  </li>
                  <li>
                    <i className="fas fa-angle-double-right"></i>Between 24 and
                    30 days – 25% of total ground arrangement cost
                  </li>
                  <li>
                    <i className="fas fa-angle-double-right"></i>Less than 24
                    days – no refund of total ground arrangement cost
                  </li>
                </ul>
                <p>
                  Commercial air flight and airfare cancellation charges will be
                  incurred by you in accordance with Airline Tariff Regulations
                  and will vary according to the type of airfare utilized.
                </p>
                <p>
                  Our cancellation provisions also hereby override any
                  conflicting cancellation provisions set out by any individual
                  operators or suppliers used by us. No refund is available to
                  you for cancellations after commencement of Your Travel
                  Arrangements or on any services not used for any reason.
                  Cancellation policies differ according to each boutique
                  property. 100% non-refundable cancellation fee will be charged
                  accordingly
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_9}>
              <p>
                {" "}
                <samp>9</samp>Health, Passport Visas
              </p>
            </button>

            {show_9 && (
              <div>
                <p>
                  It is your responsibility to ensure that you and other members
                  of your party obtain the right inoculations and medical advice
                  before you travel. Similarly, it is your responsibility to
                  ensure that you and other members of your party have correct
                  and valid passports, visas and have conformed to all other
                  requirements pertaining to your travel arrangements before the
                  holiday commences. Should you require assistance on this
                  matter we will be pleased to make recommendations. We will not
                  be held responsible for you travelling without the correct
                  travel documents.
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_10}>
              <p style={{ color: "rgb(133, 133, 133)" }}>
                <samp>10</samp>International and Domestic Air Booking
              </p>
            </button>
            {show_10 && (
              <div>
                <p>
                  Reconfirmation of flight bookings is your responsibility. As
                  Travel Arrangements may be booked to connect with
                  international and domestic air flights it is Your or the
                  travelers responsibility to keep us updated as to flight times
                  and numbers.
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_11}>
              <p>
                {" "}
                <samp>11</samp>Travel Insurance
              </p>
            </button>
            {show_11 && (
              <div>
                <p>
                  You are strongly recommended to take out personal travel
                  insurance, at the time of booking, for all members of your
                  party. It is your responsibility to ensure that the insurance
                  you purchase is adequate. Booking acceptance shall be deemed
                  to include your confirmation that you have taken out such
                  insurance. If required Seylon tour can also provide you
                  personal travel insurance related consult with combined
                  reputed insurance company for the time spent in the island,
                  please contact us for further details.
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_12}>
              <p style={{ color: "rgb(133, 133, 133)" }}>
                {" "}
                <samp>12</samp>Responsibility
              </p>
            </button>
            {show_12 && (
              <div>
                <p>
                  We will only accept liability for incidents that arise as a
                  direct result of our proven negligence, or that of our
                  suppliers, in respect of arrangements forming part of your
                  holiday itinerary, in cases where all your holiday
                  accommodation and transfers are arranged by us for the entire
                  extent of your stay. This applies to illness, injury and/or
                  death. NOTE: WE DO NOT ACCEPT LIABILITY FOR THE NEGLIGENCE OF
                  OUR SUPPLIERS IN ACCOMMODATION-ONLY BOOKINGS. IN ALL SUCH
                  CASES WE ACT SOLELY AS A BOOKING AGENT AND ACCOMMODATION IS
                  CHOSEN AT YOUR OWN RISK. We act only as a co-ordinator in
                  instances where only part of the holiday itinerary is arranged
                  by us for all passengers "(including you)" with respect to
                  services provided by certain underlying operators, including
                  hotels, lodges, restaurants, road transport providers,
                  professional guides, air charter services, boating, rafting,
                  kayaking, bush walking, trekking and climbing activities. All
                  bookings with us are therefore subject to the limitations of
                  liability imposed by the underlying operators/suppliers
                  involved in your Travel Arrangements and Seylon tour cannot be
                  held responsible for the failure of any component of the
                  holiday for which We do not have direct responsibility. This
                  includes any difficulty in finding hotels if customers choose
                  to book accommodation-only deals and arrange their own
                  transport. There are circumstances where it is impossible to
                  fulfill the contract as a result of war, the threat of war,
                  riot, civil strife, industrial dispute, terrorist activity,
                  natural or nuclear disasters, fire or adverse weather
                  conditions and all similar events outside our control. In
                  these circumstances we shall not be liable for compensation
                  where we are forced to cancel, delay, curtail or change your
                  holiday in any way or where our performance of the contract is
                  affected or prevented. We assume no responsibility for any
                  loss, damage to baggage, property or for injury illness or
                  death or for any damages or claims however so caused arising
                  directly or indirectly from accidents, loss or damage to
                  person or property, delays, transport failures, strikes, war,
                  force majeure, Acts of God etc. or any other loss arising from
                  or pursuant to Your Travel Arrangements. We accept
                  responsibility for ensuring the holidays which you book with
                  us are supplied as described and that the services we are
                  contractually obliged to provide are to a reasonable standard.
                  We work with different operators and service providers for
                  accommodation or other services and facilities which you will
                  be using and changes may occur. On occasion especially during
                  low season, certain facilities may not be open or available
                  due to maintenance, weather conditions, power cuts, and lack
                  of demand or for other reasons. In such circumstances we
                  cannot be held responsible for advertised facilities being
                  unavailable. There may be instances where alternative
                  accommodation or services may need to be provided, in such
                  instances we will ensure this is of a similar or superior
                  standard.
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_13}>
              <p>
                {" "}
                <samp>13</samp>Breakages and Damages
              </p>
            </button>
            {show_13 && (
              <div>
                <p>
                  You will be responsible for any breakages, losses or damage
                  caused by you or a member of your party during your stay in
                  the accommodation. It is your responsibility to put right the
                  damage or to make full payment to the supplier before
                  departure and to indemnify us against any losses, damages or
                  claims made against us.
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_14}>
              <p style={{ color: "rgb(133, 133, 133)" }}>
                {" "}
                <samp>14</samp>Behavior
              </p>
            </button>
            {show_14 && (
              <div>
                <p>
                  You are responsible for compliance with all relevant
                  regulations governing carriage by air or any other mode of
                  transport and your stay during your holiday. If in the opinion
                  of our suppliers/service providers and/or guide representative
                  your behaviour is disruptive or causing distress, injury or
                  danger to other clients, our staff or any other third party,
                  your holiday may be terminated and we shall not be responsible
                  for any costs or claims that are incurred.
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_15}>
              <p>
                {" "}
                <samp>15</samp>Severability
              </p>
            </button>
            {show_15 && (
              <div>
                <p>
                  If any of these Booking Conditions are invalid or
                  unenforceable then subject to law, all other provisions of
                  these Booking Conditions shall be and continue to be valid and
                  enforceable.
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_16}>
              <p style={{ color: "rgb(133, 133, 133)" }}>
                {" "}
                <samp>16</samp>Arbitration
              </p>
            </button>
            {show_16 && (
              <div>
                <p>
                  When your booking is confirmed with us, a contract between
                  Seylon tour and / or you is constituted. That contract and
                  these Booking Terms and Conditions shall be governed in all
                  respects by the laws of Sri Lanka.
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_17}>
              <p>
                {" "}
                <samp>17</samp>Complains
              </p>
            </button>
            {show_17 && (
              <div>
                <p>
                  We would wish to resolve amicably any reasonable complaint
                  that we receive relating to the standard of your travel or
                  accommodation arrangements. However, you must try to resolve
                  any complaint immediately that you have with the supplier of
                  the service direct "(i.e. the accommodation owner, airline or
                  individual service provider)" while also at the same time
                  keeping the Seylon tour representative and travel counselor
                  who handled your booking informed of all such complaints as
                  and when they arise. If your complaint cannot be resolved
                  locally you must follow this up by writing a formal complaint
                  letter to us within 28 days of your return home incorporating
                  all other relevant information. It is a condition of this
                  agreement that you communicate any problem to the supplier of
                  the service/s in question and inform the relevant Seylon tour
                  representative and travel counselor who handled your booking
                  and if still not resolved then follow this up with a written
                  complaint. If you fail to follow this simple procedure we
                  cannot accept responsibility as we have been deprived of the
                  opportunity to investigate and rectify the matter. We are
                  always looking for ways to improve our service and our
                  products and as your feedback can help us to do this we will
                  provide a Customer Survey for your completion.
                </p>
              </div>
            )}
            <button className="accordion" onClick={handlShow_18}>
              <p style={{ color: "rgb(133, 133, 133)" }}>
                {" "}
                <samp>18</samp>Exclusion of Liability
              </p>
            </button>
            {show_18 && (
              <div>
                <p>
                  Seylon tour does not guarantee that all information available
                  is free from errors or omissions but we will use all
                  reasonable endeavors to correct any errors or omissions once
                  notified of them. Our opinions are our own. Whilst we
                  rigorously strive for accuracy, these opinions are necessarily
                  subjective to some degree. All recommendations, opinions
                  expressed and prices regarding properties and/or destinations
                  displayed are provided for guidance only. You agree as the
                  user that the use of, or reliance upon, any of the content of
                  the Traveler Art website, and in particular any content
                  recommending any hotel, product or service, is done entirely
                  at your risk. "(Including content in subsequent itinerary
                  documents sent to you)" our web site links are provided to you
                  for your reference and convenience only. We do not own or
                  control the content of those websites and are not responsible
                  for their content or your use of them. We have endeavored to
                  portray and describe each hotel and/or service as best we can
                  and also to provide the most up to date information through
                  our website and other communications with you to the best of
                  our knowledge. We cannot be held liable for any discrepancies
                  or differences that might arise between the content of our
                  website "(text, images etc)", any communication sent to you
                  "(via email, fax etc)" and the actual property you are
                  accommodated at or the service you are extended.
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_19}>
              <p>
                {" "}
                <samp>19</samp>Information Provided By You
              </p>
            </button>
            {show_19 && (
              <div>
                <p>
                  All information provided by you should be true and accurate.
                  We will treat it confidentially and not forward it to any
                  third party. We have a strict anti-fraud policy and take
                  responsible steps to protect personal information such as
                  credit card details.
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_20}>
              <p style={{ color: "rgb(133, 133, 133)" }}>
                {" "}
                <samp>20</samp>Shopping
              </p>
            </button>
            {show_20 && (
              <div>
                <p>
                  Seylon tour, under no circumstances, recommends or guarantees
                  the value or the quality of any additional product not clearly
                  itemized on the Travel itinerary which may be purchased during
                  an itinerary arranged in part or whole by US, even in the case
                  where the product may have been discussed between a client and
                  an employee or representative of Seylon tour. Determining the
                  value of a product is entirely the client’s responsibility.
                  Sri Lanka tour packages have no duty to intervene in such
                  circumstances and cannot be held responsible for subsequent
                  complaints.
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_21}>
              <p>
                <samp>21</samp>Indemnification
              </p>
            </button>
            {show_21 && (
              <div>
                <p>
                  You agree to defend and indemnify Seylon tour, its affiliates,
                  and/or their respective suppliers and any of their officers,
                  directors, employees and agents from and against any claims,
                  causes of action, demands, recoveries, losses, damages, fines,
                  penalties or other costs or expenses of any kind or nature
                  including but not limited to reasonable legal and accounting
                  fees, brought by:
                </p>
                <ul>
                  <li>
                    "(i)" you or on your behalf in excess of the liability
                    described above;
                  </li>
                  <li>
                    "(ii)" by third parties as a result of "(a)" your breach of
                    this agreement or the documents referenced herein, "(b)"
                    your violation of any law or the rights of a third party, or
                    "(c)" your use of this website.
                  </li>
                </ul>
              </div>
            )}

            <button className="accordion" onClick={handlShow_22}>
              <p style={{ color: "rgb(133, 133, 133)" }}>
                {" "}
                <samp>22</samp>Electronic Communications
              </p>
            </button>
            {show_22 && (
              <div>
                <p>
                  You communicate with us electronically whenever you visit our
                  website or send emails to us. You consent to receive
                  communications from us electronically and by fax. You agree
                  that all notices, disclosures, agreements and other
                  communications that we provide to you electronically or by fax
                  satisfy any legal requirements that communications be in
                  writing. Seylon tour makes no representations or warranties,
                  and hereby excludes all warranties, express or implied, as to
                  the quality, accuracy, feasibility or credibility of the
                  content of the Seylon tour website or of any goods or services
                  offered on the site. Moreover Seylon tour accepts no liability
                  for any virus or any other type of electronic infection that
                  may be transferred through the site. Under no circumstance
                  will we be liable for any loss or damage caused by a user’s
                  reliance on information obtained through this site. We do not
                  take responsibility for consumer comments made in interactive
                  areas of the website, but we do reserve the right to moderate
                  them to comply with Sri Lankan law.
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_23}>
              <p>
                <samp>23</samp>Copyright
              </p>
            </button>
            {show_23 && (
              <div>
                <p>
                  The copyright and all other Intellectual Property rights in
                  all of the material on this website are owned by Seylon tour.
                  Copying of any part for commercial or business use is strictly
                  prohibited.
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_24}>
              <p style={{ color: "rgb(133, 133, 133)" }}>
                {" "}
                <samp>24</samp>Governing Law and Jurisdiction
              </p>
            </button>
            {show_24 && (
              <div>
                <p>
                  These terms and conditions are subject to the Laws of Sri
                  Lanka.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Condition;
