const menuItems = [
  {
    title: "Tours",
    url: "/sri-lanka-tour-packages",
    cName: "nav-links",
  },
  {
    title: "Group Offers",
    url: "/offers",
    cName: "nav-links",
  },
  {
    title: "Blog",
    url: "/blog",
    cName: "nav-links",
  },
  {
    title: "About Us",
    url: "/about",
    cName: "nav-links",
  },
  {
    title: "Contact",
    url: "/contact",
    cName: "nav-links",
  },
];

export default menuItems;
