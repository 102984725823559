import React, { useState } from "react";
import "./getDiscount.scss";
import axios from "axios";

const GetDiscount = () => {
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");

  const handleSend = async (e) => {
    e.preventDefault();
    const data = {
      email,
      promotionName: "Get 10% Discount",
      promoCode :"GET10%OFF",
    };
    try {
      const response = await axios.post("/win_trip_sri_lanka", data);
      if (response.status === 200) {
        setMessage("Thank you! You have successfully entered the contest.");
      }
    } catch (error) {
      setMessage("Oops! Something went wrong. Please try again.");
    }
    setEmail("")
  };



  return (
    <div className="getDiscount">
      <div className="getDiscount_form" id="a">
        <form onSubmit={handleSend}>
          <label>Your E-Mail</label>

          <input
            type="text"
            placeholder="Enter your email here…"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input type="submit" className="btn" />
        </form>
        {message && <small>{message}</small>}
      </div>
      <div className="getDiscount_info" id="b">
        <div className="off">
          <div className="off_amount">
            <span>10% OFF</span>
          </div>
          <span>Get a discount</span>
        </div>
        <h2>
          <em className="spFont" >Travel In Comfort: </em>
          <br /> Luxury Travel Planning For You
        </h2>
        <p>
          Offers a personalized, lavish travel experience tailored to your
          desires. Plus, enjoy an exclusive 10% off when you join our promotion
          today!
        </p>
      </div>
    </div>
  );
};

export default GetDiscount;
