export const images = [
  require("../../img/__01.jpg"),
  require("../../img/__2.jpg"),
  require("../../img/__3.jpg"),
  require("../../img/__4.jpg"),
  require("../../img/__15.jpg"),
  require("../../img/__16.jpg"),
  require("../../img/__7.jpg"),
  require("../../img/__8.jpg"),
  require("../../img/__9.jpg"),
  require("../../img/__10.jpg"),
  require("../../img/__11.jpg"),
  require("../../img/__12.jpg"),
  require("../../img/__13.jpg"),
  require("../../img/__14.jpg"),
  require("../../img/__17.webp"),
  require("../../img/__18.avif"),
  require("../../img/__19.avif"),
  require("../../img/__26.jpg"),
  require("../../img/__21.avif"),
  require("../../img/__20.avif"),
];