import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const PrivacyPolicy = () => {


      const [show_1, setShow_1] = useState(false);
      const [show_2, setShow_2] = useState(false);
      const [show_3, setShow_3] = useState(false);
      const [show_4, setShow_4] = useState(false);
      const [show_5, setShow_5] = useState(false);
      const [show_6, setShow_6] = useState(false);





  const handlShow_1 = () => {
    setShow_1(!show_1);
  };

  const handlShow_2 = () => {
    setShow_2(!show_2);
  };
  const handlShow_3 = () => {
    setShow_3(!show_3);
  };
  const handlShow_4 = () => {
    setShow_4(!show_4);
  };
  const handlShow_5 = () => {
    setShow_5(!show_5);
  };
  const handlShow_6 = () => {
    setShow_6(!show_6);
  };


  return (
    <div className="__fw">
      <div className="__mw">
        <div className="container_introduction">
          <div className="tours_head">
            <h1>Privacy Policy</h1>
          </div>
          <div className="all_poly">
            <div className="all_poly_info">

              <p>
                {" "}
                LeisureryStays (Pvt) Ltd is committed to protecting any personal
                information that you may provide to us. In particular, we
                believe it is important for you to know how we treat information
                about you that we may receive from this website. This privacy
                policy explains how Seylon tour may collect information from you
                via our website and how that information may be used
                subsequently. If you have any queries in relation to this
                privacy policy, please contact info@seylontour.com.
                <br />
                <br />
                By using our website you agree to the terms stated herein, if
                you do not accept these terms you should not use our website.
                The terms contained in this privacy policy only pertain to the
                https://www.seylontour.com website. We also provide links and
                have agreements with other websites for marketing and other
                purposes which you may visit directly through our website;
                however we are not responsible for these websites their security
                or privacy practices/policies and these websites are not subject
                to the terms contained in this policy.
                <br />
                <br />
                You are advised to read the terms and conditions and privacy
                policy of each individual website for your own safety.
                <br />
                <br />
                We reserve the right to change the contents of this policy which
                are subject to change without prior notification.
                <br />
                <br />
                You are advised to regularly read this policy when you visit our
                website.
                <br />
              </p>

              <br />
            </div>

            <button className="accordion" onClick={handlShow_1}>
              <p>
                {" "}
                <samp>01</samp> Collection of information
              </p>
            </button>
            {show_1 && (
              <div>
                <p>
                  In order to ensure we deliver the service that our customers
                  require and provide the best possible product closest to their
                  requirements we will endeavor to sometimes find out necessary
                  information from you or about you. We do this by asking you,
                  by reading your comments and also looking at areas of our site
                  that are the most popular.
                </p>
                <br />
              </div>
            )}

            <button className="accordion" onClick={handlShow_2}>
              <p>
                <samp>02</samp> Collection of personal information
              </p>
            </button>
            {show_2 && (
              <div>
                <p>
                  We will only collect personal information from you (such as
                  name, address, telephone number, email address etc) which you
                  have provided voluntarily to us either by completing
                  registration or enquiry forms, sending emails to us or signing
                  up for our newsletter service. Please do not submit your
                  personal data to us if you do not wish us to collect it.
                  <br />
                  Examples of where we may collect your personal information
                  (without limitation)
                  <br />
                  If you sign up for our newsletter service we ask for your name
                  and e-mail address. This is to help us to mail out the
                  requested newsletter effectively.
                  <br />
                  If you submit an inquiry we ask for your name, telephone
                  number and e-mail address. This is to enable us to return the
                  requested information to you effectively.
                  <br />
                  If you enter a contest or promotion we ask for your name,
                  address and e-mail address. This is to enable us to run the
                  contest effectively and let you know if you have won.
                  <br />
                  If you order a product or service from us we may ask for
                  information including your name, e-mail address, delivery and
                  billing address, credit or debit card number and expiry date.
                  Sometimes we may ask for your telephone number. These details
                  will help us to process your orders efficiently.
                  <br />
                </p>
                <br />
              </div>
            )}

            <button className="accordion" onClick={handlShow_3}>
              <p>
                <samp>03</samp>Collection of Other Information
              </p>
            </button>
            {show_3 && (
              <div>
                <p>
                  {" "}
                  In addition to asking you to submit personal information, we
                  may collect information about you automatically when you visit
                  the site (as is done by most websites). The collection of
                  information may involve the use of cookies and web beacons. A
                  web beacon is a small graphic image placed on a web page that
                  is designed to allow us to monitor visitors to our site. A
                  cookie is small text file that is placed on your computer’s
                  hard drive by your web browser when you first visit our site.
                  The cookie allows us to identify your computer and find out
                  details about your last visit. Most internet browsers accept
                  cookies automatically, but usually you can alter the settings
                  of your browser to erase cookies or prevent automatic
                  acceptance if you prefer. If you choose not to receive our
                  cookies, we cannot guarantee that your experience with our
                  website will be as quick or responsive as if you do receive
                  cookies.
                  <br />
                  The information collected by cookies and web beacons is not
                  personally identifiable, it includes general information about
                  your computer settings, your connection to the internet e.g.
                  operating system and platforms, IP address, your browsing
                  patterns and timings of browsing on our website and your
                  geographical location.
                </p>
                <br />
              </div>
            )}
            <button className="accordion" onClick={handlShow_4}>
              <p>
                <samp>04</samp> Use of Collected Information
              </p>
            </button>

            {show_4 && (
              <div>
                <p>
                  Domain name information that we collect is not used to
                  personally identify you and instead is aggregated to measure
                  the number of visits, average time spent on the site, pages
                  viewed, etc. We use this information to measure the use of our
                  site and to improve the content of our site.
                  <br />
                  <br />
                  Usually, we use the personal information you provide only to
                  respond to your inquiry or to process your request. This
                  information may be shared with other subsidiaries of the
                  LeisureryStays (Pvt) Ltd, but only if necessary to fulfill your
                  request or for related purposes.
                  <br />
                  <br />
                  We may obtain, hold, correlate, merge and use your information
                  (including personal information) for the purpose of providing
                  you with our services and ancillary services and for our
                  internal business purposes such as:
                  <br />
                  <br />
                  Inform you of special promotions, and other marketing related
                  activities.
                  <br />
                  Compiling customer reviews and testimonials For market
                  research To prevent offensive or illegal content from being
                  posted or uploaded onto our site Personalizing your visits to
                  the site which will enable us to better cater to your
                  individual needs and tastes To enable our vendors and
                  suppliers to conduct statistical analysis or other required
                  functions related to the business. For accounting purposes For
                  internal planning purposes
                  <br />
                  <br />
                  We may share the personal information you provide with other
                  companies we have hired to provide services for us. These
                  companies – our vendors – are contractually bound to use
                  personal information we share with them only to perform the
                  services we have hired them to provide. We do not share, sell,
                  or lease personal information about you to any third parties
                  for their marketing use. We will release information about you
                  if you direct us to do so, if we are required by law to do so,
                  or in other legally limited circumstances (for example, to
                  protect your account from fraud).
                  <br />
                  <br />
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_5}>
              <p>
                <samp>05</samp> Security of Collected Information
              </p>
            </button>

            {show_5 && (
              <div>
                <p>
                  In general, you can visit this website without telling us who
                  you are or revealing any information about yourself. We
                  maintain strict physical, electronic, and administrative
                  safeguards to protect your personal information from
                  unauthorized or inappropriate access. We restrict access to
                  information about you to those Seylon tour associates who need
                  to know the information to respond to your inquiry or request.
                  Associates who misuse personal information are subject to
                  disciplinary action.
                  <br />
                  Access to Collected Information
                  <br />
                  You may review and update the information that you provide to
                  us through this Web site by contacting us on
                  info@seylontour.com.
                  <br />
                </p>
              </div>
            )}

            <button className="accordion" onClick={handlShow_6}>
              <p>
                <samp>06</samp>Contacting Us
              </p>
            </button>

            {show_6 && (
              <div>
                <p>
                  If you have any questions about this privacy policy, or no
                  longer wish to receive newsletters and other services you have
                  signed up for please email us on info@seylontour.com. We
                  welcome your questions and suggestions about our privacy
                  policy.
                </p>
              </div>
            )}
          </div>

          <div className="info_key">
            <p>
              sri lanka tour packages, sri lanka tour, best tour packages in sri
              lanka, places to visit in sri lanka, sri lanka honeymoon packages,
              sri lanka travel package, sri lanka 5 days itinerary, sri lanka
              tour package, sri lanka trip, sri lanka tour itinerary, sri lanka
              tourism, sri lanka cheap tour packages, sri lanka itinerary 4
              days, srilanka tour package, 5 day trip to sri lanka, 7 days in
              sri lanka, sri lanka itinerary, sri lanka itinerary 1 week, sri
              lanka itinerary 6 days, sri lanka package holidays, sri lanka
              trip, cheap tour sri lanka, five days in sri lanka, holiday in
              colombo sri lanka, places to visit in sri lanka in 5 days, sri
              lanka holiday, sri lanka tour operators, sri lanka travel
              itinerary, sri lanka trip package, sri lanka trip planner, 4 day
              tour sri lanka, 4 days in sri lanka, 4 nights 5 days sri lanka
              package, day tours in sri lanka, sri lanka trip packages, sri
              lanka city tour, sri lanka 7 days tour package, sri lanka city
              tour, sri lanka ground tour package, sri lanka holiday itinerary,
              sri lanka in a week, sri lanka luxury tours, sri lanka tour
              itinerary, sri lanka vacation, srilanka tour package itinerary
              tour package to sri lanka from malaysia, sri lanka in 7 days,
              dynasty travel sri lanka, place to visit in sri lanka, sri lanka
              honeymoon, sri lanka itinerary 7 days, sri lanka holidays, 6 day
              sri lanka tour, sri lanka tour packages from malaysia, freelance
              tour guide in sri lanka, sri lanka tour package from singapore,
              sri lanka 5 day itinerary, tour package to sri lanka, honeymoon in
              sri lanka, sri lanka land tour packages, sri lanka honeymoon
              package, sri lanka travel packages from singapore, sri lanka
              resort tours from singapore, sri lanka tour singapore, travel sri
              lanka, sri lanka tourist attractions, sri lanka tour package
              singapore, srilanka tourism, sri lanka tourist attractions, places
              to visit sri lanka, sri lanka places of interest, 5 days in sri
              lanka, visit sri lanka tours, sri lanka package tour from
              singapore, sri lanka tours from malaysia
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy
