import React from "react";
import "./whatapp.css";

const Whatapp = () => {
  return (
    <div className="wht_wrp">
      <a href="https://wa.me/94778302226" target="_blank">
        <div className="whatapp-icon">
          <span className="what-text"> Connect with an Expert </span>{" "}
          <img src={require("../../img/WhatsApp-Logo.wine.png")} alt="" />
        </div>{" "}
      </a>{" "}
      <div className="anim_icon">
        <span class="indicator">
          <span class="indicate">&nbsp;</span>
          <span class="indicate-border">&nbsp;</span>
          <span class="indicate-border indicate-border-1">&nbsp;</span>
          <span class="indicate-border indicate-border-2">&nbsp;</span>
          <span class="indicate-border indicate-border-3">&nbsp;</span>
          <span class="indicate-border indicate-border-4">&nbsp;</span>
        </span>
      </div>
    </div>
  );
};

export default Whatapp;
