import React, { useState } from "react";
import "./inqForm.css";
import axios from "axios";

const InqForm = ({ tour_name }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [pax, setPax] = useState("");
  const [accommodation, setAccommodation] = useState("");
  const [country, setCountry] = useState("");
  const today = new Date().toISOString().split("T")[0];
  const [date, setDate] = useState(today);
  const [message, setMessage] = useState("");
  const [know, setKnow] = useState("");
  const [sucMessage, setSucMessage] = useState("");

  const makeInquiry = async (e) => {
    e.preventDefault();
    const inqData = {
      name,
      email,
      phone,
      pax,
      date,
      message,
      know,
      tour_name,
      accommodation,
      country,
    };

    console.log(inqData);

    try {
      await axios.post("/tour_inquiry", inqData);
      setSucMessage(
        "Thank you for inquiring about our tour. Our specialist will reach out to you soon."
      );
    } catch (error) {
      console.log(error);
    }
    setName("");
    setEmail("");
    setPhone("");
    setPax("");
    setDate("");
    setMessage("");
    setKnow("");
    setAccommodation("");
    setCountry("");
  };

  return (
    <div id="inquiry" class="inquiry">
      <div class="in_head">
        <h2 style={{ color: "#4e8fee" }}>
          You can send your enquiry via the form below
        </h2>
      </div>

      <div class="container_inner1_2_pkgInfo_2">
        <form onSubmit={makeInquiry}>
          <input
            type="text"
            name="name"
            id="name"
            value={name}
            placeholder="Your Name"
            required="required"
            onChange={(e) => setName(e.target.value)}
          />

          <input
            type="text"
            name="email"
            id="email"
            value={email}
            placeholder="Your Email Address"
            required="required"
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            type="tel"
            name="phone"
            id="phone"
            value={phone}
            placeholder="Your Phone Number"
            required="required"
            onChange={(e) => setPhone(e.target.value)}
          />

          <input
            required
            type="text"
            name="country"
            id="country"
            min={1}
            value={country}
            placeholder="Singaporean"
            onChange={(e) => setCountry(e.target.value)}
          />

          <input
            required
            type="number"
            name="pax"
            id="pax"
            min={1}
            value={pax}
            placeholder="How many pax will Travel"
            onChange={(e) => setPax(e.target.value)}
          />

          <input
            style={{ width: "100%", padding: "10px" }}
            type="date"
            name="tourdate"
            id="tourdate"
            value={date}
            placeholder="Date Plan to Travel"
            onChange={(e) => setDate(e.target.value)}
          />

          <textarea
            name="message"
            id="message"
            cols="30"
            rows="7"
            data-parsley-required="1"
            value={message}
            placeholder="Type your message here..."
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>

          <select
            name="dropdown"
            size="1"
            id="dropdown"
            value={accommodation}
            onChange={(e) => setAccommodation(e.target.value)}
          >
            <option>Accommodation Type</option>
            <option value="5* Hotel & Resort">5* Hotel & Resort</option>
            <option value="Luxury Hotels & Villas">
              Luxury Hotels & Villas
            </option>
            <option value="3-4 Star Hotels">3-4 Star Hotels</option>
            <option value="1-2 Star Hotels">1-2 Star Hotels</option>
            <option value="Guest House/Bungalows">Guest House/Bungalows</option>
          </select>

          <select
            name="dropdown"
            size="1"
            id="dropdown"
            value={know}
            onChange={(e) => setKnow(e.target.value)}
          >
            <option>How did you hear about us?</option>
            <option value="Google">Google</option>
            <option value="Facebook">Facebook</option>
            <option value="Friend">Friend</option>
            <option value="Other">Other</option>
          </select>
          <br />
          <br />
          <button class="shopButton2" type="submit">
            {" "}
            Send Enquiry
          </button>
        </form>
        <small>{sucMessage}</small>
        <div className="or">
          <span> - or - </span>
        </div>

        <a href="https://wa.me/94778302226" target="_blank">
          <div className="wht_app">
            <img src={require("../../img/whap.png")} alt="" />
            <span>Talk to Tour Expert</span>
          </div>
        </a>
      </div>

      {/* 
          <div id="myModal2" class="modal2">
            <div class="modal2-content">
              <span class="close2">&times;</span>
              <h1>Thank you</h1>
              <p>for your inquiry</p>
            </div>
          </div> */}

      {/* <div id="inquiry" class="inquiry">
            <div class="in_head">
              <h2>your feedback</h2>
              <p>
                Your opinion is important to us. This way we can keep improving
                our service
              </p>
            </div>

             <div class="container_inner1_2_pkgInfo_2">
              <form id="niq_form" action="/Tours/feedback.php" method="post">
                <div class="stars" data-rating="3">
                  <span class="star">&nbsp;</span>
                  <span class="star">&nbsp;</span>
                  <span class="star">&nbsp;</span>
                  <span class="star">&nbsp;</span>
                  <span class="star">&nbsp;</span>
                </div>

                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email Address"
                />

                <textarea
                  name="message"
                  rows="6"
                  cols="25"
                  placeholder="Write your value feedback here...."
                ></textarea>
                <br />
                <br />

                <input class="shopButton2" type="submit" value="Send" />
              </form>
            </div> 
          </div> */}
    </div>
  );
};

export default InqForm;
