import React from "react";

const Guidelines = () => {
  return (
    <>
      <div className="sub_branch">
        <div className="subBranch">
          <ul className="breadcrumb_2">
            <li>
              <a href="/">
                <img
                  className="icon3"
                  src="/static/images/home-solid .svg"
                  alt="seylontour"
                />
              </a>
            </li>

            <li>Guidelines</li>
          </ul>
        </div>
      </div>

      <div className="slGuid">
        <div className="container_introduction">
          <div className="all_covd">
            <div className="all_covd_info">
              <h2>
                Sri Lanka Tourism Operational <span>Guidelines</span>{" "}
              </h2>
              <br />
              <h3>
                Sri Lanka Tourism ready to welcome tourists from 1st Aug 2020
              </h3>
              <br />
              <br />
            </div>
            <div className="covd_date">
              <p>
                (Posted on June 5, 2020) <br />
                Sri Lanka is readying to welcome travelers from across the world
                with safety guidelines and precautionary measures.
              </p>
              <br />
              <p style="font-weight: 500; color: #00ceff;">
                Sri Lanka Tourism: Operational Guidelines with Health Protocols
                <span>
                  {" "}
                  <a href="https://www.srilanka.travel/pdf/SL_Tourism_Operational_Guidelines.pdf">
                    (Click here)
                  </a>
                </span>
              </p>
              <br />
              <h2>Frequently Asked Questions</h2>
            </div>
            <br />
            <br />

            <button className="accordion">
              <h3>
                {" "}
                <samp>1. </samp> Who can visit ?
              </h3>
            </button>
            <div>
              <p>
                All nationalities are welcome and all types of travellers
                whether groups, families, individuals.
              </p>
              <br />
            </div>

            <button className="accordion">
              <h3 style={{ color: "rgb(133, 133, 133)" }}>
                {" "}
                <samp>2.</samp> Which airports will operate ?
              </h3>
            </button>
            <div>
              <p>
                Bandaranaike International Airport [BIA], Colombo Ratmalana
                Airport [RMA] and Mattala Rajapaksa Hambantota Airport [MRIA]
                will be operating for tourist arrivals from Aug 1st 2020
              </p>
              <br />
            </div>

            <button className="accordion">
              <h3>
                {" "}
                <samp>3.</samp> What are the visa procedures ?
              </h3>
            </button>
            <div>
              <ul>
                <li>
                  1. All travelers will require a valid visa; this will be
                  issued for 30 days and extendable for up to 6 months after
                  arrival. Online visa at http://www.eta.gov.lk/slvisa/
                </li>
                <li>
                  2. Further relaxation for long stay guests is currently under
                  consideration, enabling tourist to be granted five-year
                  multiple entry visas with maximum 6 months stay.
                </li>
                <li>3. Visa fee US$100</li>
              </ul>
              <br />
            </div>
            <button className="accordion">
              <h3 style={{ color: "rgb(133, 133, 133)" }}>
                {" "}
                <samp>4.</samp> Information required for visa application
              </h3>
            </button>
            <div>
              <p>
                <ul>
                  <li>1. Booking details at Certified Accommodation</li>
                  <li>2. Itinerary in Sri Lanka</li>
                  <li>3. Return ticket</li>
                  <li>4. Proof of medical insurance</li>
                </ul>
                <br />
              </p>
            </div>

            <button className="accordion">
              <h3>
                {" "}
                <samp>5.</samp> Where can I stay and is there a minimum duration
                ?
              </h3>
            </button>
            <div>
              <p>
                <ul>
                  <li>1. A minimum 5-night stay in Sri Lanka is required</li>
                  <li>
                    2. Independent professional audits will be done to certify
                    accommodation providers who will be listed on the Sri Lanka
                    tourism website. Details of bookings made with Certified
                    Accommodation providers are required for the visa
                    application
                  </li>
                </ul>
                <br />
              </p>
            </div>

            <button className="accordion">
              <h3 style={{ color: "rgb(133, 133, 133)" }}>
                {" "}
                <samp>6.</samp> Pre departure Covid-19 PCR testing
              </h3>
            </button>
            <div>
              <p>
                A negative PCR test is required prior to boarding your flight
                and on arrival in Sri Lanka. The test should be no older than 72
                hours prior to boarding.
              </p>
            </div>

            <button className="accordion">
              <h3>
                {" "}
                <samp>7.</samp> On arrival in Sri Lanka
              </h3>
            </button>
            <div>
              <p>
                The airport will have high intensity sanitation measures in
                place for all areas and staff.
              </p>
              <br />
              <p>
                <ul>
                  <li>
                    1. There is no quarantine on arrival unless symptoms are
                    detected.
                  </li>
                  <li>
                    2. A PCR test would be done on arrival at the airport. No
                    charge.
                  </li>
                  <li>
                    3. Currently it takes 24 hours to receive the test results.
                    We are making changes to have results within 4-6 hours by
                    August. In the event it is required to wait 24 hours for
                    test results; you will be able to select a hotel for a
                    one-night stay from Certified 4 or 5-star hotels in Colombo
                    or Negombo until the test results are received.
                  </li>
                  <li>
                    4. A further test will be done 4-5 days after arrival by a
                    mobile unit in coordination with your Certified
                    Accommodation provider.
                  </li>
                  <li>
                    5. Travelers staying for more than 10 days will be required
                    to take a third test during their stay. While this may be
                    inconvenient it is essential to safeguard everybody and
                    provide peace of mind.
                  </li>
                  <li>
                    6. In case of a positive PCR result, quarantine in a
                    designated hotel or hospitalisation will take place
                    depending on the details of the case. Quarantine would
                    usually be 14 to 21 days if symptoms are detected.
                  </li>
                </ul>
                <br />
              </p>
            </div>

            <button className="accordion">
              <h3 style={{ color: "rgb(133, 133, 133)" }}>
                {" "}
                <samp>8.</samp> Moving around Sri Lanka
              </h3>
            </button>
            <div>
              <p>
                <ul>
                  <li>
                    1. To ensure your safety, please arrange transportation
                    prior to your arrival, with your Certified Accommodation
                    provider or travel agent. Public transport should not be
                    used.
                  </li>
                  <li>
                    2. All tourist sites will be open from the 1st August
                    onwards with safety protocols and measures in place to
                    ensure the wellbeing of our travelers.
                  </li>
                  <li>
                    3. There will be no travel restrictions between districts
                    for tourists.
                  </li>
                </ul>
                <br />
              </p>
            </div>

            <button className="accordion">
              <h3>
                {" "}
                <samp>9.</samp> What is Sri Lanka’s status with COVID-19 ?
              </h3>
            </button>
            <div>
              <p>
                Being an island, and thanks to the proactive measures taken, Sri
                Lanka has been able to manage the spread of the virus. No active
                community clusters have been detected in the last 30 days within
                the country.
              </p>
            </div>
            <p>Source: https://srilanka.travel/travel-news&news=729</p>
          </div>

          <div className="info_key">
            <p>
              sri lanka tour packages, sri lanka tour, best tour packages in sri
              lanka, places to visit in sri lanka, sri lanka honeymoon packages,
              sri lanka travel package, sri lanka 5 days itinerary, sri lanka
              tour package, sri lanka trip, sri lanka tour itinerary, sri lanka
              tourism, sri lanka cheap tour packages, sri lanka itinerary 4
              days, srilanka tour package, 5 day trip to sri lanka, 7 days in
              sri lanka, sri lanka itinerary, sri lanka itinerary 1 week, sri
              lanka itinerary 6 days, sri lanka package holidays, sri lanka
              trip, cheap tour sri lanka, five days in sri lanka, holiday in
              colombo sri lanka, places to visit in sri lanka in 5 days, sri
              lanka holiday, sri lanka tour operators, sri lanka travel
              itinerary, sri lanka trip package, sri lanka trip planner, 4 day
              tour sri lanka, 4 days in sri lanka, 4 nights 5 days sri lanka
              package, day tours in sri lanka, sri lanka trip packages, sri
              lanka city tour, sri lanka 7 days tour package, sri lanka city
              tour, sri lanka ground tour package, sri lanka holiday itinerary,
              sri lanka in a week, sri lanka luxury tours, sri lanka tour
              itinerary, sri lanka vacation, srilanka tour package itinerary
              tour package to sri lanka from malaysia, sri lanka in 7 days,
              dynasty travel sri lanka, place to visit in sri lanka, sri lanka
              honeymoon, sri lanka itinerary 7 days, sri lanka holidays, 6 day
              sri lanka tour, sri lanka tour packages from malaysia, freelance
              tour guide in sri lanka, sri lanka tour package from singapore,
              sri lanka 5 day itinerary, tour package to sri lanka, honeymoon in
              sri lanka, sri lanka land tour packages, sri lanka honeymoon
              package, sri lanka travel packages from singapore, sri lanka
              resort tours from singapore, sri lanka tour singapore, travel sri
              lanka, sri lanka tourist attractions, sri lanka tour package
              singapore, srilanka tourism, sri lanka tourist attractions, places
              to visit sri lanka, sri lanka places of interest, 5 days in sri
              lanka, visit sri lanka tours, sri lanka package tour from
              singapore, sri lanka tours from malaysia
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Guidelines;
