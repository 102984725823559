import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppsIcon from "@mui/icons-material/Apps";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { FaCar } from "react-icons/fa";
import { FaBed } from "react-icons/fa";
import { GiHotMeal } from "react-icons/gi";
import { BiSolidTree } from "react-icons/bi";

import "./tourPage.scss";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import InqForm from "../../components/inqForm/InqForm";
import Tandc from "../../components/T&c";
import Faqs from "../../components/Faqs";

const TourPage = () => {
  const id = useParams();
  const [show_1, setShow_1] = useState(false);
  const [data, setData] = useState("");
  const [showAllPhotos, setShowAllPhotos] = useState(false);

  const tourId = id._id;

  const handlShow_1 = () => {
    setShow_1(!show_1);
  };

  const getSingalTour = async () => {
    try {
      await axios.get(`/get_full_tour/${tourId}`).then((response) => {
        setData(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingalTour();
  }, []);

  if (showAllPhotos) {
    return (
      <div className="__fw">
        <div className="__mw">
          <div className="show_all_pic ">
            <div className="show_in" style={{ width: "100%", padding: "20px" }}>
              <h2>
                {" "}
                <em className="spFont">Places that</em> you will see
              </h2>
              <div className="close_btn">
                <span onClick={() => setShowAllPhotos(false)}>
                  <div className="btn_back">
                    <ArrowBackIosIcon /> Back
                  </div>
                </span>
              </div>
              {data?.tourPhotos?.length > 0 &&
                data.tourPhotos.map((addedPhotos, index) => (
                  <div key={index} className="full_img">
                    <img
                      style={{ width: "100%" }}
                      src={`${process.env.REACT_APP_TOUR_UPLOAD_LINK}/${addedPhotos}`}
                      alt=""
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="__fw">
      <div className="__mw">
        <div className="_image_gallry">
          <div className="image_grid_main">
            <div className="grid_one ">
              {data.tourPhotos?.[0] && (
                <img
                  src={`${process.env.REACT_APP_TOUR_UPLOAD_LINK}/${data.tourPhotos[0]}`}
                  alt=""
                />
              )}
              <span onClick={() => setShowAllPhotos(true)}>
                <div className="more_pic">
                  <AppsIcon style={{ fontSize: "medium" }} />{" "}
                  <small>Show all photos</small>
                </div>
              </span>
            </div>
            <div className=" grid_two">
              <div className="grid_two_main">
                <div className="grid_two_in">
                  {data.tourPhotos?.[0] && (
                    <img
                      src={`${process.env.REACT_APP_TOUR_UPLOAD_LINK}/${data.tourPhotos[1]}`}
                      alt=""
                    />
                  )}
                </div>
              </div>
              <div className="grid_two_main">
                <div className="grid_two_in">
                  {data.tourPhotos?.[0] && (
                    <img
                      src={`${process.env.REACT_APP_TOUR_UPLOAD_LINK}/${data.tourPhotos[2]}`}
                      alt=""
                    />
                  )}
                </div>
              </div>
            </div>
            <div className=" grid_two">
              <div className="grid_two_main">
                <div className="grid_two_in">
                  {data.tourPhotos?.[0] && (
                    <img
                      src={`${process.env.REACT_APP_TOUR_UPLOAD_LINK}/${data.tourPhotos[3]}`}
                      alt=""
                    />
                  )}
                </div>
              </div>
              <div className="grid_two_main">
                <div className="grid_two_in">
                  {data.tourPhotos?.[0] && (
                    <img
                      src={`${process.env.REACT_APP_TOUR_UPLOAD_LINK}/${data.tourPhotos[4]}`}
                      alt=""
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tour_info">
          <div className="tour_left_wrp">
            <div className="tour_left_had">
              <h1>{data.heading}</h1>
              <h2>
                {" "}
                <em className="spFont">{data.subHeading}</em>{" "}
              </h2>
            </div>

            <div className="include_wrp">
              <span>
                <FaCar className="icon" /> Transfer Included
              </span>
              <span>
                <FaBed className="icon" /> Stay Included
              </span>
              <span>
                <GiHotMeal className="icon" />
                Meals Included
              </span>
              <span>
                <BiSolidTree className="icon" />
                Sightseeing Included
              </span>
            </div>

            <div className="itinerary_wrp">
              <div class="container_introduction">
                <h2>Package Itinerary</h2>
                {data.itinerary?.map((item, i) => (
                  <>
                    <button class="accordion" onClick={handlShow_1} key={i}>
                      <div className="accordion_btn">
                        <p>
                          <span className="date_wrp">Day{item.date}</span>
                          {item.heading}
                        </p>
                        <p>
                          <MdOutlineKeyboardArrowDown />
                        </p>
                      </div>
                    </button>
                    {show_1 && (
                      <div class="panel_in">
                        <div class="panel_text">
                          <p>{item.description}</p>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>

            <div className="map_wrp">
              <div className="GC_map">
                <h2>Itinerary map</h2>
                <div class="inner_map">
                  <iframe
                    src={data.tourMap}
                    frameborder="0"
                    //   style="border: 0;"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </div>
              </div>
            </div>

            <div className="tandc">
              <Tandc />
            </div>

            <div className="faqs">
              <Faqs />
            </div>
          </div>
          <div className="tour_right_wrp">
            <InqForm tour_name={data.heading} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourPage;
