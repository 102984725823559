import React from 'react'
import { Link } from 'react-router-dom';

const ResponsibleTourism = () => {
  return (
    <div className="__fw">
      <div className="__mw">
        <div className="resCon">
          <div className="tours_head">
            <h1>Responsible Tourism</h1>
          </div>
          <div className="res_info">
            <p>
              Sri Lanka has more-than 2500 years long history of Buddhism,
              culture & tourism that exposure Seylon tour share with world
              today. The name Pahiyanagala or Fahiyanagala is coined from the
              name of Fa-Hsien, after a Chinese pilgrim who visited this cave in
              the 05th century during his two-year pilgrimage in Sri Lanka, this
              is one of oldest evidence of pilgrimage tourism in Sri Lanka. The
              Seylon tour symbolic hospitality to travelers became via Sri
              Lankan tradition so every single holiday seekers treated with the
              greatest care and attention. <br />
              <br />
              Responsible Tourism according to Cape Town Declaration: “making
              better places for people to live in and better places for people
              to visit”. The way of achieve that
              <div className="res_list">
                <ul className="res_bulet">
                  <li>
                    {" "}
                    <samp>1</samp> Minimises’ negative economic, environmental
                    and social impacts.
                  </li>
                  <li>
                    {" "}
                    <samp>2</samp> Generates greater economic benefits for local
                    people and enhances the well-being of host communities, br
                    improves working conditions and access to the industry.
                  </li>
                  <li>
                    {" "}
                    <samp>3</samp> Involves local people in decisions that
                    affect their lives and life changes.
                  </li>
                  <li>
                    {" "}
                    <samp>4</samp> Makes positive contributions to the
                    conservation of natural and cultural heritage, to the
                    maintenance of the world’s diversity.
                  </li>
                  <li>
                    <samp>5</samp> Minimises’ negative economic, environmental
                    and social impacts.Generates greater economic benefits for
                    local people and enhances the well-being of host
                    communities, improves working conditions and access to the
                    industry.Involves local people in decisions that affect
                    their lives and life changes.Makes positive contributions to
                    the conservation of natural and cultural heritage, to the
                    maintenance of the world’s diversity.Provides more enjoyable
                    experiences for tourists through more meaningful connections
                    with local people, and a greater understanding of local
                    cultural, social and environmental issues.
                  </li>
                  <li>
                    {" "}
                    <samp>6</samp> Provide access for people with disabilities
                    and the disadvantaged.
                  </li>
                  <li>
                    <samp>7</samp> Is culturally sensitive, engenders respect
                    between tourists and hosts, and builds local pride and
                    confidence.
                  </li>
                </ul>
              </div>
            </p>
            <h2>Seylon tour Responsible Tourism</h2>
            <p>
              Start before the holiday seekers inquire us, we maintain proper
              promotion plan to give responsible details to travelers who wants
              to better holiday. Reputed client consultant team appoint (24/7)
              within operation office & internationally to give correct,
              accurate details to travelers via website as well as verbally.
            </p>
            <br /> <br />
            <h2>Seylon tour way of People & Culture</h2>
            <p>
              Seylon tour always discourage Abuse or Sex Tourism, in any part of
              the country or world. Decimal number of travelers & Service
              providers expect or think Beach Boys, War affected children or
              women or street people who does not have any shelter, can be used
              to their any kind of satisfaction but Seylon tour strictly
              discourage it. We encourage our travelers if this kind of abuse
              happen in anywhere within their tour can report our Guide or
              directly to team members. Food: Seylon tour offer all the
              travelers to experience Sri Lankan traditional food & Specially
              very sweet fruits during the tour, ex: Plate of Red-Banana, Mango
              or Pineapple taste and bring to Sri Lankan traditional food
              restaurant for Lunch to experience the Rice & Curry, this most of
              the places run by Elderly persons or Supply chain mainly depend on
              farmers in village so to having the lunch there will be benefited
              all of them.
            </p>
            <br /> <br />
            <h2>Wild-Life</h2>
            <p>
              Seylon tour totally discourage Elephant trekking or Elephant
              riding, this is very famous in Pinnawala & Habarana area. This
              experience give travelers to more-close untouched environment but
              for that there are lot of "Sacrifice" by Big Asian Elephants,
              normally per Elephant has to carry 06 people in one time & make
              minimum 30mins ride then at-least 12 or more rides has to do one
              Elephant per day without any rest most probably. The way of
              training an Elephant, treat or their foot was getting injured
              because of Chair always with them in 24/7, those kind of all abuse
              activities promotion stopped by Seylon tour.
            </p>
            <br />
            <br />
            <p>
              But every time Seylon tour encourage visit Pinnawala Elephant
              orphanage, that place can see how Sri Lankan treat the Big Asian
              Animals in better-way.
            </p>
          </div>

          <div className="info_key">
            <p>
              sri lanka tour packages, sri lanka tour, best tour packages in sri
              lanka, places to visit in sri lanka, sri lanka honeymoon packages,
              sri lanka travel package, sri lanka 5 days itinerary, sri lanka
              tour package, sri lanka trip, sri lanka tour itinerary, sri lanka
              tourism, sri lanka cheap tour packages, sri lanka itinerary 4
              days, srilanka tour package, 5 day trip to sri lanka, 7 days in
              sri lanka, sri lanka itinerary, sri lanka itinerary 1 week, sri
              lanka itinerary 6 days, sri lanka package holidays, sri lanka
              trip, cheap tour sri lanka, five days in sri lanka, holiday in
              colombo sri lanka, places to visit in sri lanka in 5 days, sri
              lanka holiday, sri lanka tour operators, sri lanka travel
              itinerary, sri lanka trip package, sri lanka trip planner, 4 day
              tour sri lanka, 4 days in sri lanka, 4 nights 5 days sri lanka
              package, day tours in sri lanka, sri lanka trip packages, sri
              lanka city tour, sri lanka 7 days tour package, sri lanka city
              tour, sri lanka ground tour package, sri lanka holiday itinerary,
              sri lanka in a week, sri lanka luxury tours, sri lanka tour
              itinerary, sri lanka vacation, srilanka tour package itinerary
              tour package to sri lanka from malaysia, sri lanka in 7 days,
              dynasty travel sri lanka, place to visit in sri lanka, sri lanka
              honeymoon, sri lanka itinerary 7 days, sri lanka holidays, 6 day
              sri lanka tour, sri lanka tour packages from malaysia, freelance
              tour guide in sri lanka, sri lanka tour package from singapore,
              sri lanka 5 day itinerary, tour package to sri lanka, honeymoon in
              sri lanka, sri lanka land tour packages, sri lanka honeymoon
              package, sri lanka travel packages from singapore, sri lanka
              resort tours from singapore, sri lanka tour singapore, travel sri
              lanka, sri lanka tourist attractions, sri lanka tour package
              singapore, srilanka tourism, sri lanka tourist attractions, places
              to visit sri lanka, sri lanka places of interest, 5 days in sri
              lanka, visit sri lanka tours, sri lanka package tour from
              singapore, sri lanka tours from malaysia
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResponsibleTourism
