import React from 'react'
import './navBar.scss'
import { Link } from 'react-router-dom';
import seylontourlog from "../../img/seylontourlog.svg";
import { RiWhatsappFill } from "react-icons/ri";

const NavBar = () => {
  return (
    <div className="__fw">
      <div className="__mw">
        <div className="navBar">
          <div className="_logo">
            <Link to={"/"}>
              <img src={seylontourlog} alt="seylontour logo" />
            </Link>
          </div>
          <div className="_links">
            <Link to={"/sri-lanka-tour-packages"}>
              <div className="link">
                <span>Tours</span>
              </div>
            </Link>
            <Link to={"/offers"}>
              <div className="link">
                <span>Group Offers</span>
              </div>
            </Link>
            <Link to={"/blog"}>
              <div className="link">
                <span>Blog </span>
              </div>
            </Link>
            <Link to={"/about"}>
              <div className="link">
                <span>About Us</span>
              </div>
            </Link>
            <Link to={"/contact"}>
              <div className="link">
                <span>Contact</span>
              </div>
            </Link>
            {/* <Link>
              <div className="link">
                <span>Login</span>
              </div>
            </Link> */}
          </div>
          <div className="phone_info">
            <a href="https://wa.me/94778302226" target="_blank">
              <RiWhatsappFill color="	#25D366" />
              <span>(+94)778302226</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar
