import React from "react";
import "./contact.scss";
import Offer from "../../components/offer/Offer";
import { FaPhoneAlt } from "react-icons/fa";
import { SlEnvolope } from "react-icons/sl";
import { AiOutlineHome } from "react-icons/ai";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <>
      <div className="__fw">
        <div className="__mw">
          <div className="pag_headign">
            <small className="subTag">Contact us</small>
            <h1>
              <em className="spFont">The World Awaits,</em> Are You Ready?
            </h1>
          </div>
          <hr />
        </div>
      </div>
      <div className="__fw">
        <div className="__mw">
          <div className="contact_info">
            <div className="cont_item">
              <div className="con_icon">
                <div className="con_icon_in">
                  <FaPhoneAlt className="_icon" />
                </div>
              </div>
              <div className="con_info">
                <label>WhatsApp</label>
                <span>+94778302226</span>
                <label>Tel</label>
                <span>+94112849268 +94112849426 </span>
              </div>
            </div>
            <div className="cont_item">
              <div className="con_icon">
                <div className="con_icon_in">
                  <SlEnvolope className="_icon" />
                </div>
              </div>
              <div className="con_info">
                <label>Email</label>
                <span>info@seylontour.com</span>
                <span>support@seylontour.com</span>
              </div>
            </div>
            <div className="cont_item">
              <div className="con_icon">
                <div className="con_icon_in">
                  <AiOutlineHome className="_icon" />
                </div>
              </div>
              <div className="con_info">
                <label>Address</label>
                <span>
                  87/23A,Kohila Kotuwa Road,Maharagama 10280,Sri Lanka{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="__fw" style={{ backgroundColor: "#F3F4F7" }}>
        <div className="__mw">
          <div className="we_dont_wrp">
            <div className="we_dont">
              <h2>
                Ready to start planning{" "}
                <em className="spFont">your next adventure?</em> Contact us
                today to start creating unforgettable memories.
              </h2>
            </div>
            <div className="we_dont_img">
              <div className="img_we">
                <img src={require("../../img/place_s.png")} alt="" />
              </div>
              <div className="text_we">
                <span>enjoy your vacation</span>
                <Link to={"/sri-lanka-tour-packages"}>
                  <p>get in touch</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="__fw">
        <div className="__mw">
          <div className="__contact">
            <div className="__tour_adv_right">
              <img src={require("../../img/travelite-08.webp")} alt="" />
            </div>
            <div className="__tour_adv_left">
              <small className="subTag">get in touch</small>
              <h1>
                <em className="spFont">Explore The World,</em> <br /> Expand
                Your Mind
              </h1>
              <form>
                <div className="form_wrp">
                  <div className="input_wrp">
                    <div>
                      <label> Your Name </label>
                      <input type="text" />
                    </div>{" "}
                    <div>
                      <label>E-mail address</label>
                      <input type="text" />
                    </div>
                  </div>
                  <div>
                    <label> Your Message</label>
                    <textarea cols="40" rows="3" type="textarea" />
                  </div>
                </div>
                <div className="sub_btn">Submit</div>
              </form>
            </div>
          </div>
          <Offer />
        </div>
      </div>
    </>
  );
};

export default Contact;
