
import React from "react";
import WeAreTrusted from "../../components/weAreTrusted/WeAreTrusted";
import Video from "../../components/video/Video";
import WhyWe from "../../components/whyWe/WhyWe";
import { MdOutlineStarPurple500 } from "react-icons/md";
import './about.scss'
import { IoMdCheckmark } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { SlEnvolope } from "react-icons/sl";
import { AiOutlineHome } from "react-icons/ai";
import { Link } from "react-router-dom";

const About = () => {
   const scrollTop = () => {
     window.scrollTo(0, 0);
   };
  return (
    <>
      <div className="__fw">
        <div className="__mw">
          <div className="pag_headign">
            <small className="subTag">about us</small>
            <h1>
              {" "}
              <em className="spFont">The Journey Of A Lifetime:</em> Create
              Unforgettable Memories
            </h1>
          </div>
          <hr />
          <WeAreTrusted />
        </div>
        <div className="whowe">
          <div className="out_line_main">
            <div className="out_line"></div>
            <div className="subTag_name">
              <div className="subTag_name_wrp">
                <Link to={"/group"}>
                  <small onClick={scrollTop} className="subTag">
                    Who We Are
                  </small>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Video embedId="YmXW3_KpGls" />
        <div className="__mw">
          <WhyWe />
        </div>

        <div className="__mw">
          <div className="line">
            <div className="_star">
              <p>positive reviews</p>
              <span>
                <MdOutlineStarPurple500 className="_icon" />
                <MdOutlineStarPurple500 className="_icon" />
                <MdOutlineStarPurple500 className="_icon" />
                <MdOutlineStarPurple500 className="_icon" />
                <MdOutlineStarPurple500 className="_icon" />
              </span>
            </div>
          </div>

          <div className="service">
            <h1>services we provide</h1>
            <div className="service_wrp">
              <ul>
                <li>
                  {" "}
                  <IoMdCheckmark className="__icon" /> Personalized Tour
                  Packages
                </li>
                <li>
                  {" "}
                  <IoMdCheckmark className="__icon" />
                  Flexible Tour Durations
                </li>
                <li>
                  {" "}
                  <IoMdCheckmark className="__icon" />
                  Tailor-Made Experiences
                </li>
                <li>
                  {" "}
                  <IoMdCheckmark className="__icon" />
                  Travel Assistance
                </li>
              </ul>
              <ul>
                <li>
                  {" "}
                  <IoMdCheckmark className="__icon" />
                  Luxury and Comfort
                </li>
                <li>
                  {" "}
                  <IoMdCheckmark className="__icon" />
                  Adventure and Exploration
                </li>
                <li>
                  {" "}
                  <IoMdCheckmark className="__icon" />
                  Exclusive Services
                </li>
                <li>
                  {" "}
                  <IoMdCheckmark className="__icon" />
                  Air Tickets Assistance{" "}
                </li>
              </ul>
              <ul>
                <li>
                  {" "}
                  <IoMdCheckmark className="__icon" />
                  Sustainable Tourism
                </li>
                <li>
                  {" "}
                  <IoMdCheckmark className="__icon" />
                  Corporate and Incentive Tours
                </li>
                <li>
                  {" "}
                  <IoMdCheckmark className="__icon" />
                  Family and Group Travel
                </li>
              </ul>
            </div>
          </div>

          <div className="pag_headign">
            <small className="subTag">Contact us</small>
            <h1>
              <em className="spFont">The World Awaits</em> Are You Ready?
            </h1>
          </div>
          <hr />
          <div className="contact_info">
            <div className="cont_item">
              <div className="con_icon">
                <div className="con_icon_in">
                  <FaPhoneAlt className="_icon" />
                </div>
              </div>
              <div className="con_info">
                <label>WhatsApp</label>
                <span>+94778302226</span>
              </div>
            </div>
            <div className="cont_item">
              <div className="con_icon">
                <div className="con_icon_in">
                  <SlEnvolope className="_icon" />
                </div>
              </div>
              <div className="con_info">
                <label>Email</label>
                <span>info@seylontour.com</span>
                <span>support@seylontour.com</span>
              </div>
            </div>
            <div className="cont_item">
              <div className="con_icon">
                <div className="con_icon_in">
                  <AiOutlineHome className="_icon" />
                </div>
              </div>
              <div className="con_info">
                <label>Address</label>
                <span>Maharagama</span>
                <span>Colombo</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="__fw" style={{ backgroundColor: "#F3F4F7" }}>
        <div className="__mw">
          <div className="we_dont_wrp">
            <div className="we_dont">
              <h2>
                Ready to start planning{" "}
                <em className="spFont">your next adventure?</em> Contact us
                today to start creating unforgettable memories.
              </h2>
            </div>
            <div className="we_dont_img">
              <div className="img_we">
                <img src={require("../../img/place_s.png")} alt="" />
              </div>
              <div className="text_we">
                <span>enjoy your vacation</span>
                <Link to={"/sri-lanka-tour-packages"} onClick={scrollTop}>
                  <p>get in touch</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
