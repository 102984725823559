import React from 'react'
import { Link } from 'react-router-dom';

const Faqs = () => {

   const scrollTop = () => {
     window.scrollTo(0, 0);
   };


  return (
    <>
      <div className="InoneF">
        <div className="faqs2">
          <img
            style={{ width: "80px" }}
            src={require("../img/faq.png")}
            alt="seylontour"
          />
          <br />
          <h2 style={{ color: "#4e8fee" }}>
            {" "}
            can I customize this Tour planas to my desires ?
          </h2>
          <br />
          <h3>yes definitely you can!</h3>
          <br />
          <div className="faq">
            <p>
              {" "}
              We’ve shared some of our most frequently asked questions to help
              you out !
            </p>

            <div className="faq_icon">
              <Link to={"/faqs"} className="shopButton3" onClick={scrollTop}>
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faqs
