import React, { useEffect, useState } from "react";
import "./groupTour.scss";
import AppsIcon from "@mui/icons-material/Apps";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import axios from "axios";
import { useParams } from "react-router-dom";
import { MdAccessTime } from "react-icons/md";
import { FaUmbrellaBeach } from "react-icons/fa6";
import { HiOutlineUserGroup } from "react-icons/hi";
import { LuLanguages } from "react-icons/lu";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import Clock from "../../components/timer/Clock";

const GroupTour = () => {
  const id = useParams();
  const [showAllPhotos, setShowAllPhotos] = useState(false);
  const [data, setData] = useState("");

  const tourId = id._id;

  const getSingalTour = async () => {
    try {
      await axios.get(`/get_full_tour/${tourId}`).then((response) => {
        setData(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingalTour();
  }, []);

  if (showAllPhotos) {
    return (
      <div className="show_all_pic ">
        <div className="show_in" style={{ width: "100%", padding: "20px" }}>
          <h2>
            {" "}
            <em className="spFont" >Places that</em> you will see
          </h2>
          <div className="close_btn">
            <span onClick={() => setShowAllPhotos(false)}>
              <ArrowBackIosIcon /> Back
            </span>
          </div>
          {data?.tourPhotos?.length > 0 &&
            data.tourPhotos.map((addedPhotos, index) => (
              <div key={index}>
                <img
                  style={{ width: "100%" }}
                  src={`${process.env.REACT_APP_TOUR_UPLOAD_LINK}/${addedPhotos}`}
                  alt=""
                />
              </div>
            ))}
        </div>
      </div>
    );
  }

  return (
    <div className="__fw">
      <div className="mainContainer">
        <div className="image_gal">
          <div className="image_con">
            <img src={require("../../img/sqr_banner_Arugam Bay.jpg")} alt="" />
          </div>
          <div className="image_wrap">
            <div className="image_in_con">
              <img
                src={require("../../img/sqr_banner_Daladamaliga.jpg")}
                alt=""
              />
            </div>
            <div className="image_in_con">
              <img src={require("../../img/sqr_banner_deniyaya.jpg")} alt="" />
            </div>
          </div>
          <div className="image_con">
            <img src={require("../../img/tour.jpg")} alt="" />
          </div>
        </div>

        <div className="tour_sum">
          <div className="tour_sum_in">
            <h2>DISCOVER 05-DAY TOUR IN SRI LANKA</h2>
          </div>
          <div className="tour_sum_info">
            <div className="item">
              <div className="logo">
                <MdAccessTime size={25} />
              </div>
              <div className="info">
                <h3>Duration</h3>
                <small>5 Days</small>
              </div>
            </div>
            <div className="item">
              <div className="logo">
                <FaUmbrellaBeach size={25} />
              </div>
              <div className="info">
                <h3>Type</h3>
                <small>Beache</small>
              </div>
            </div>
            <div className="item">
              <div className="logo">
                <HiOutlineUserGroup size={25} />
              </div>
              <div className="info">
                <h3>Group Size</h3>
                <small>10 Pax Min</small>
              </div>
            </div>
            <div className="item">
              <div className="logo">
                <LuLanguages size={25} />
              </div>
              <div className="info">
                <h3>Languages</h3>
                <small>English</small>
              </div>
            </div>
            <div className="item">
              <div className="logo">
                <RiMoneyDollarCircleLine size={25} />
              </div>
              <div className="info">
                <h2>499.00</h2>
                <small>person</small>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default GroupTour;
