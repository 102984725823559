import React, { useEffect, useState } from "react";
import "./blog.scss";
import { useParams } from "react-router-dom";
import axios from "axios";

const BlogPage = () => {
  const { id } = useParams();
  const [blogData, setBlogData] = useState(null);

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const response = await axios.get(`/getSingalBlog/${id}`);
        setBlogData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getBlogs();
  }, [id]);

  if (!blogData) return <div>Loading...</div>; // Handle loading state

  return (
    <div className="__fw">
      <div className="__mw">
        <div className="blogPage">
          <div className="blog_sec">
            <h1>{blogData.heading}</h1>
            <div className="image">
              <img
                src={`${process.env.REACT_APP_BLOG_IMAGE_UPLOAD_LINK}/${blogData.blogPhotos}`}
                alt={blogData.heading}
              />
            </div>
            <div className="blog_content">
              <div dangerouslySetInnerHTML={{ __html: blogData.blog }} />
            </div>
            <hr />
            <div className="blog_date">
              <span>By Admin | {new Date(blogData.date).toDateString()}</span>
            </div>
          </div>
          <div className="blog_filter">
            <h2>Categories</h2>
            <p>{blogData.category}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
