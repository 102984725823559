import React, { useEffect, useState } from "react";
import taxi from "../img/taxi-solid.png";
import eye from "../img/eye-solid.png";
import map from "../img/map-marker-alt-solid.png";
import user from "../img/user-solid.png";
import bed from "../img/bed-solid.png";
import Tailormade from "../img/Tailormade.png";

import banner from "../img/TC-DAY.jpg";

import train from "../img/sqr_banner_train.jpg";
import sea from "../img/sqr_banner8-underwater.jpg";
import { Link } from "react-router-dom";
import axios from "axios";
import Popular from "../components/Popular";

export default function WomenTours() {
  const [data, setData] = useState([]);
  const [types, setTypes] = useState("Women");
  const [category, setCategory] = useState("");
  const [error, setError] = useState("");

  const url = data.map((link) => link.heading.toLowerCase().replace(/ /g, "-"));

  console.log(url);

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  const getData = async () => {
    try {
      const response = await axios.get(`/get_all_tours`, {
        params: { types: types, category: category },
        headers: {
          "Content-Type": "application/json",
        },
      });
      setData(response.data);
      setError(""); // Clear any previous errors
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError("No matching tours found. Try something else.");
      } else {
        setError("An error occurred. Please try again later.");
      }
      setData([]); // Clear data if there's an error
    }
  };

  useEffect(() => {
    getData();
  }, [category, types]);

  return (
    <>
      <div
        className="TourBanner_W"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          objectFit: "contain",
        }}
      ></div>

      <div className="sub_branch">
        <div className="subBranch">
          <ul className="breadcrumb_2">
            <li>
              <Link to={"/"}>
                <img
                  className="icon3"
                  src={require("../img/home-solid.png")}
                  alt="seylontour"
                />
              </Link>
            </li>

            <li>
              <Link to={"/sri-lanka-tour-package"}>Tours</Link>
            </li>

            <li>Women Tours Packages</li>
          </ul>
        </div>
      </div>

      <div className="TourContainer">
        <div className="TCone">
          <h3>Women's Special Tour Packages</h3>
          <br />
          <p>
            {" "}
            <samp style={{ fontSize: "30px" }}>S</samp>et your spirit free.
            Enjoy with all your senses. Let your hair down. It’s time to have a
            blast Special tour welcomes women to celebrate their own bonding
            time! Travel alone or with your grandmother, mother, daughter or
            even sister-in-law. Enjoy the benefits of Women’s Special tour
            packages.
          </p>
        </div>
        <div className="TCtwo">
          <h2>
            Women's <span>Tour</span>
          </h2>
          <br />

          <div className="allTour">
            {error && <p>{error}</p>}
            {!error && data.length > 0
              ? data.map((item, i) => (
                  <Link
                    onClick={handleScroll}
                    to={`/sri-lanka-tour-package/${item.heading
                      .toLowerCase()
                      .replace(/ /g, "-")}/${item._id}`}
                    key={i}
                  >
                    <div className="tourCard" key={i}>
                      <div className="image">
                        <img
                          style={{ minHeight: "200px" }}
                          src={`${process.env.REACT_APP_TOUR_UPLOAD_LINK}/${item.tourPhotos[0]}`}
                          alt=""
                        />
                      </div>

                      <div className="CSetwo">
                        <h2>{item.heading}</h2>
                        <span>{item.subHeading}</span>
                        <div className="icons">
                          <img src={taxi} alt="Transport Include" />
                          <img src={eye} alt="Sightseeing" />
                          <img src={map} alt="Locations" />
                          <img src={user} alt="English Speaking Chauffeur" />
                          <img src={bed} alt="Hotel Accommodation" />
                          <p>{item.description}</p>
                        </div>
                      </div>
                      <div className="CSeThree">
                        <h3>
                          PRICE ON <br /> REQUEST
                        </h3>
                        <a
                          className="shopButton4"
                          href="{% url 'sri-lanka-5-days-tour' %}"
                        >
                          VIEW TOUR
                        </a>
                        <br />
                        <h4>* * *</h4>
                      </div>
                    </div>
                  </Link>
                ))
              : !error && <p>Loading tours...</p>}
          </div>
        </div>
      </div>
      <Popular />
    </>
  );
}
