import React, { useState } from "react";
import PropTypes from "prop-types";
import "./video.scss";
import { FaPlay } from "react-icons/fa6";

const Video = ({ embedId }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };

  return (
    <div className="video_wrp">
      <img src={require("../../img/_TC-BC.jpg")} alt="" />
      <div className="video_text">
        <div className="btn" onClick={handleShow}>
          <FaPlay />
        </div>
        <h2>Take Tour Sri Lnaka</h2>
        <p>
          Sri Lanka has more Unesco World Heritage Sites,kandy sri lanka, in
          such a small area than anywhere else on earth and over 2000 years of
          culture can be discovered at the ancient sites where legendary temples
          boast beautiful details. and friendly sri lanka language, But Sri
          Lanka is not just renowned for its ancient history it is also home to
          miles and miles of beautiful beaches, and tea estates in the central
          hill ranges. Sri Lanka’s wonderful landscape is invitingly lined with
          pure white sand. Diving, snorkelling, or just soaking up the seemingly
          endless warmth and sun are reward in themselves. White dagobas curving
          sky-ward create a starting skyline, serene carved stone Buddha’s lying
          in permanent silence and elephants in parades glittering in gold
        </p>
      </div>
      {show && (
        <div className="video_wrp_main">
          <div className="video-responsive">
            <iframe
              src={`https://www.youtube.com/embed/${embedId}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
          <div className="close_btn" onClick={handleShow}>
            Close
          </div>
        </div>
      )}
    </div>
  );
};

Video.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default Video;
