import React from "react";
import "./ourAdv.scss";

const OurAdv = () => {
  return (
    <div className="tour_adv">
      <div className="tour_adv_left" id="a">
        <small className="subTag">Our advantages</small>
        <h1>
          {" "}
          <em className="spFont" >Travel In Style:</em> <br /> Luxury Getaways For The Discerning
        </h1>
        <p>
          Our team of experts has spent years honing their skills in the travel
          industry, and we’ve built a reputation for providing unparalleled
          service and attention to detail. We know that every traveler is
          different, and we take the time to get to know you before planning
          your trip.
        </p>
        {/* <div className="out_line"></div> */}
      </div>

      <div className="tour_adv_right" id="b">
        <img src={require("../../img/advantages.png")} alt="" />
      </div>
    </div>
  );
};

export default OurAdv;
