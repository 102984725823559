import React from 'react'
import "./ourGallery.scss";
import "./OurGalleryRight.scss";
import { images } from "./image.js";

const OurGallery = () => {
  return (
    <>
      <div className="img_gal_wrp">
        <div className="img_sec">
          <div className="img_sec_in">
            <img src={`${images[0]}`} alt={`Gallery ${images[0]}`} />
          </div>
          <div className="img_sec_in">
            <img src={`${images[1]}`} alt={`Gallery ${images[1]}`} />
          </div>
        </div>

        <div className="img_sec">
          <div className="img_sec_in">
            <img src={`${images[2]}`} alt={`Gallery ${images[2]}`} />
          </div>
          <div className="img_sec_in">
            <img src={`${images[3]}`} alt={`Gallery ${images[3]}`} />
          </div>
        </div>

        <div className="img_sec_one">
          <img src={`${images[4]}`} alt={`Gallery ${images[4]}`} />
        </div>
      </div>
    </>
  );
}

export default OurGallery
