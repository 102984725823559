import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Popular from "../../components/Popular";
import SideInfo from "../../components/SideInfo";
import Tandc from "../../components/T&c";
import Faqs from "../../components/Faqs";
import axios from "axios";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import "./image_gallery.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AppsIcon from "@mui/icons-material/Apps";
import './tour.css'
import InqForm from "../../components/inqForm/InqForm";

const Tour = () => {
  const id = useParams();
  const [show_1, setShow_1] = useState(false);
  const [data, setData] = useState("");
  const [showAllPhotos, setShowAllPhotos] = useState(false);

  const tourId = id._id;

  const handlShow_1 = () => {
    setShow_1(!show_1);
  };

   


  const getSingalTour = async () => {
    try {
      await axios.get(`/get_full_tour/${tourId}`).then((response) => {
        setData(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingalTour();
  }, []);

   if (showAllPhotos) {
     return (
       <div className="__fw">
         <div className="__mw">
           <div className="show_all_pic ">
             <div
               className="show_in"
               style={{ width: "100%", padding: "20px" }}
             >
               <h2>
                 {" "}
                 <em className="spFont">Places that</em> you will see
               </h2>
               <div className="close_btn">
                 <span onClick={() => setShowAllPhotos(false)}>
                   <div className="btn_back">
                     <ArrowBackIosIcon /> Back
                   </div>
                 </span>
               </div>
               {data?.tourPhotos?.length > 0 &&
                 data.tourPhotos.map((addedPhotos, index) => (
                   <div key={index} className="full_img">
                     <img
                       style={{ width: "100%" }}
                       src={`${process.env.REACT_APP_TOUR_UPLOAD_LINK}/${addedPhotos}`}
                       alt=""
                     />
                   </div>
                 ))}
             </div>
           </div>
         </div>
       </div>
     );
   }

  return (
    <div className="__fw">
      <div className="__mw">
        <div className="tour_wrp">
          <div className="tourInone">
            <div className="InoneA">
              <h1>{data.heading}</h1>
              <h2>{data.subHeading}</h2>
              <div className="_image_gallry">
                <div className="image_grid_main">
                  <div className="grid_one ">
                    {data.tourPhotos?.[0] && (
                      <img
                        src={`${process.env.REACT_APP_TOUR_UPLOAD_LINK}/${data.tourPhotos[0]}`}
                        alt=""
                      />
                    )}
                    <span onClick={() => setShowAllPhotos(true)}>
                      <div className="more_pic">
                        <AppsIcon style={{ fontSize: "medium" }} />{" "}
                        <small>Show all photos</small>
                      </div>
                    </span>
                  </div>
                  <div className=" grid_two">
                    <div className="grid_two_main">
                      <div className="grid_two_in">
                        {data.tourPhotos?.[0] && (
                          <img
                            src={`${process.env.REACT_APP_TOUR_UPLOAD_LINK}/${data.tourPhotos[1]}`}
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                    <div className="grid_two_main">
                      <div className="grid_two_in">
                        {data.tourPhotos?.[0] && (
                          <img
                            src={`${process.env.REACT_APP_TOUR_UPLOAD_LINK}/${data.tourPhotos[3]}`}
                            alt=""
                          />
                        )}
                        {/* <span onClick={() => setShowAllPhotos(true)}>
                          <div className="more_pic">
                            <AppsIcon style={{ fontSize: "medium" }} />{" "}
                            <small>Show all photos</small>
                          </div>
                        </span> */}
                      </div>
                    </div>
                  </div>
                  <div className=" grid_three">
                    <div className="grid_two_main">
                      <div className="grid_two_in">
                        {data.tourPhotos?.[0] && (
                          <img
                            src={`${process.env.REACT_APP_TOUR_UPLOAD_LINK}/${data.tourPhotos[1]}`}
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                    <div className="grid_two_main">
                      <div className="grid_two_in">
                        {data.tourPhotos?.[0] && (
                          <img
                            src={`${process.env.REACT_APP_TOUR_UPLOAD_LINK}/${data.tourPhotos[3]}`}
                            alt=""
                          />
                        )}
                        {/* <span onClick={() => setShowAllPhotos(true)}>
                          <div className="more_pic">
                            <AppsIcon style={{ fontSize: "medium" }} />{" "}
                            <small>Show all photos</small>
                          </div>
                        </span> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="InoneB">
              <div class="container_introduction">
                <h2 style={{ paddingBottom: "20px" }}>Package Itinerary</h2>
                {data.itinerary?.map((item, i) => (
                  <>
                    <button class="accordion" onClick={handlShow_1} key={i}>
                      <div className="accordion_btn">
                        <p>
                          <span>Day{item.date}</span>
                          {item.heading}
                        </p>
                        <p>
                          <MdOutlineKeyboardArrowDown />
                        </p>
                      </div>
                    </button>
                    {show_1 && (
                      <div class="panel_in">
                        <div class="panel_text">
                          <p>{item.description}</p>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>

            <div className="InoneC">
              <div className="GC_map">
                <h2>Itinerary map</h2>
                <div class="inner_map">
                  <iframe
                    src={data.tourMap}
                    frameborder="0"
                    //   style="border: 0;"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </div>
              </div>
            </div>

            <Tandc />
            {/* <div class="InoneE">
            <div class="place_will_see">
              <h2>Places You’ll Travel</h2>
              <br />
              <div class="place_pic">
                {data.tourPhotos?.map((img) => (
                  <div class="loc_img">
                    <img
                      src={`${process.env.REACT_APP_TOUR_UPLOAD_LINK}/${img}`}
                      alt=""
                    />
                    <p>Anuradhapura</p>
                  </div>
                ))}
              </div>
            </div>
          </div> */}

            <Faqs />
          </div>
          <div>
            <InqForm tour_name={data.heading} />
            <SideInfo />
          </div>
        </div>
        {/* <Popular /> */}
      </div>
    </div>
  );
};

export default Tour;
