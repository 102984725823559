import React, { useEffect, useState } from "react";
import "./allTours.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import { MdOutlineStar } from "react-icons/md";
import InqForm from "../../components/inqForm/InqForm";

const AllTours = () => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [types, setTypes] = useState("");
  const [category, setCategory] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state

  const [date, setDate] = useState("");

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  const onEditClick = () => {
    window.scrollTo(0, 0);
  };
  const handleOpen = () => {
    setShow(!show);
  };

  const getData = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.get(`/get_all_tours`, {
        params: { types: types, category: category },
        headers: {
          "Content-Type": "application/json",
        },
      });
      setData(response.data);
      setError(""); // Clear any previous errors
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError("No matching tours found. Try something else.");
      } else {
        setError("An error occurred. Please try again later.");
      }
      setData([]); // Clear data if there's an error
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    getData();
  }, [category, types]);

  return (
    <>
      <div className="__fw">
        <div className="__mw">
          <br />

          <div className="filter">
            <select
              required
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">How Many Days </option>
              <option value="2 Days">2 Days</option>
              <option value="3 Days">3 Days</option>
              <option value="5 Days">5 Days</option>
              <option value="6 Days">6 Days</option>
              <option value="7 Days">7 Days</option>
              <option value="8 Days">8 Days</option>
              <option value="9 Days">9 Days</option>
              <option value="10 Days">10 Days</option>
              <option value="12 Days">12 Days</option>
              <option value="13 Days">13 Days</option>
              <option value="14 Days">14 Days</option>
              <option value="15 Days">15 Days</option>
              <option value="28 Days">28 Days</option>
            </select>

            <select
              required
              value={types}
              onChange={(e) => setTypes(e.target.value)}
            >
              <option value="">
                Travel Experience 
              </option>
              <option value="Women">Women</option>
              <option value="Solo">Solo</option>
              <option value="VIP">VIP</option>
              <option value="Adventure">Adventure</option>
              <option value="Nature & Wild Life">Nature & Wild Life</option>
              <option value="Honeymoon">Honeymoon</option>
              <option value="Pilgrimage">Pilgrimage</option>
              <option value="Ramayana">Ramayana</option>
              <option value="Ayurveda & Wellness">Ayurveda & Wellness</option>
              <option value="Study">Study</option>
            </select>
          </div>
        
          <div className="tours_wrp">
            {loading ? ( // Show loading indicator while data is being fetched
              <div className="loading">Loading...</div>
            ) : (
              data.map((item, i) => (
                <div className="_tourCard" key={i}>
                  <div className="image">
                    <Link
                      onClick={handleScroll}
                      to={`/sri-lanka-tour-package_new/${item.heading
                        .toLowerCase()
                        .replace(/ /g, "-")}/${item._id}`}
                    >
                      <img
                        style={{ minHeight: "200px" }}
                        src={`${process.env.REACT_APP_TOUR_UPLOAD_LINK}/${item.tourPhotos[0]}`}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="_info">
                    <div className="info_date">
                      <span>
                        {item.category} & {parseInt(item.category) - 1} Nights
                      </span>
                      <strong>
                        <MdOutlineStar />
                      </strong>
                    </div>
                    <h3>{item.heading}</h3>
                    <div className="type_wrp">
                      <span>{item.types} </span>
                    </div>
                    {item.discount ? (
                      <div className="offer_amount_wrp">
                        <span>{item.discount}% OFF</span>
                      </div>
                    ) : (
                      <div className="offer_amount_wrp">
                        <span>Dinner Free</span>
                      </div>
                    )}

                    {item.price ? (
                      <div className="price_tour">
                        <span>
                          $ {parseFloat(item.price).toFixed(2)} <em>P/P</em>{" "}
                        </span>
                        <small>
                          $
                          {parseFloat(
                            (item.price * item.discount) / 100
                          ).toFixed(2)}
                        </small>
                        <em>
                          SAVE $
                          {parseFloat(item.price * 1.1 - item.price).toFixed(2)}
                        </em>
                      </div>
                    ) : (
                      <div className="price_tour">
                        <span>Price on Request </span>
                      </div>
                    )}

                    <div className="contact_wrp">
                      <a href="https://wa.me/94778302226" target="_blank">
                        <img src={require("../../img/whap.png")} alt="" />
                      </a>

                      <div className="contact_wrp_cal">
                        <Link
                          onClick={handleScroll}
                          to={`/sri-lanka-tour-package_new/${item.heading
                            .toLowerCase()
                            .replace(/ /g, "-")}/${item._id}`}
                        >
                          <strong>View Full Tour Guide</strong>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllTours;
