import React from 'react'
import "./testimonials.scss";

const Testimonials = () => {
  return (
    <div className="testimonials">
      <small className="subTag">Testimonials</small>
      <div className="tesmoSection">
        <h1>
          {" "}
          <em className='spFont' >We Love Receiving</em> Feedback
        </h1>
        <br />
        <p>
          {" "}
          from our satisfied customers here some of our client experience and
          see how they <br /> feel our services we have provided.
        </p>
        <div className="testimonial-slider">
          <ul className="slider">
            <li>
              <img
                className="proPic"
                src={require("../../img/pic1.png")}
                alt="Christina"
              />
              <div className="testimonial-slider-content">
                <q>
                  My trip was carefree because of seylontourTours. The entire
                  experience was exclusive and I came home feeling refreshed.{" "}
                  <br /> I cannot stop telling my friends about the great times
                  I've had in Sri Lanka.....
                </q>
                <p className="source">- Ms.Lee Jiawel Christina, Singapore </p>
              </div>
            </li>
            <li>
              <img
                className="proPic"
                src={require("../../img/pic2.png")}
                alt="Ian"
              />
              <div className="testimonial-slider-content">
                <q>
                  In the 10 days we got a good look at the country and saw a
                  variety of attractions. The very experienced driver was
                  extremely safe and courteous to other road users throughout
                  although I believe he could have provided more information
                  than he did to enhance our trip....
                </q>
                <p className="source">- Mr.Ian Anderson, UK</p>
              </div>
            </li>
            <li>
              <img
                className="proPic"
                src={require("../../img/pic3.png")}
                alt="Michael"
              />
              <div className="testimonial-slider-content">
                <q>
                  We could not have done this without your help. This has been a
                  special experience; we will always remember this
                </q>
                <p className="source">- Michael, USA</p>
              </div>
            </li>
            <li>
              <img
                className="proPic"
                src={require("../../img/pic4.png")}
                alt="Delannoy Family"
              />
              <div className="testimonial-slider-content">
                <q>
                  Touring To Sri Lanka Was great for our family, flexibility and
                  reassurance helped us book a wonderful and memorable
                  experience Our guide Sugath was great, the accommodations were
                  wonderful. Sugath took us on some fantastic hikes as well.
                  Sugath was fantastic Very Safe Driving , Thank you
                </q>
                <p className="source">- Delannoy Family, France</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Testimonials
