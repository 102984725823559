import React from 'react'
import OurGallery from '../../components/ourGallery/OurGallery';
import OurGalleryRight from '../../components/ourGallery/OurGalleryRight';
import { Link } from 'react-router-dom';

const Gallery = () => {
   const scrollTop = () => {
     window.scrollTo(0, 0);
   };
  return (
    <div className="__fw">
      <div className="__mw">
        <div className="pag_headign">
          <small className="subTag">Gallery</small>
          <h1>
            <em className='spFont' >Where Every Day</em> Is An Adventure
          </h1>
        </div>
        <hr />
        <OurGalleryRight />

        <div className="we_dont_wrp">
          <div className="we_dont">
            <h2>
              Ready to start planning{" "}
              <em className="spFont">your next adventure?</em> Contact us today
              to start creating unforgettable memories.
            </h2>
          </div>
          <div className="we_dont_img">
            <div className="img_we">
              <img src={require("../../img/place_s.png")} alt="" />
            </div>
            <div className="text_we">
              <span>enjoy your vacation</span>
              <Link to={"/sri-lanka-tour-packages"} onClick={scrollTop}>
                <p>get in touch</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery
