import React from "react";
import taxi from "../img/taxi-solid.svg";
import eye from "../img/eye-solid.svg";
import map from "../img/map-marker-alt-solid.svg";
import user from "../img/user-solid.svg";
import bed from "../img/bed-solid.svg";
import { Link } from "react-router-dom";

const Popular = () => {
    const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="popSection">
      <h1>Popular Tours</h1>
      <p>
        Here some of popular tour package, to select best travel experience in
        sri lanka
      </p>
      <br />
      <br />

      <div className="popTours">
        <div className="popTour">
          <Link
            onClick={handleScroll}
            className="popTourLink"
            to={
              "/sri-lanka-tour-package/vip-exclusive-tour-in-ceylon/668c7683571a52433adf4f2d"
            }
          >
            <div className="popOne">
              <div className="column">
                <div className="effect-3">
                  <div className="effect-img">
                    <img src={require("../img/pop1VIP.jpg")} alt="Image" />
                  </div>
                  <div className="effect-text">
                    <div className="effect-btn">
                      <a className="btn">VIEW TOUR</a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="popText">
                <h2>VIP Exclusive Tour In Ceylon</h2>
                <p className="txcolor"> 8 Days Tour in Sri Lanka </p>
                <div className="icons">
                  <img src={taxi} alt="Transport Include" />
                  <img src={eye} alt="Sightseeing" />
                  <img src={map} alt="Locations" />
                  <img src={user} alt="English Speaking Chauffeur" />
                  <img src={bed} alt="Hotel Accommodation" />
                </div>

                <p>
                  Air Taxi fly To Nuwara Eliya, Air Taxi fly to Colombo, Galle
                  Fort Visit, Ramboda Water Falls
                </p>
              </div>
            </div>
          </Link>
        </div>

        <div className="popTour">
          <Link
            onClick={handleScroll}
            className="popTourLink"
            to={
              "/sri-lanka-tour-package/women-essence-of-paradise/668c66eb571a52433adf4a02"
            }
          >
            <div className="popTwo">
              <div className="column">
                <div className="effect-3">
                  <div className="effect-img">
                    <img src={require("../img/pop1Solo.jpg")} alt="Image" />
                  </div>
                  <div className="effect-text">
                    <div className="effect-btn">
                      <a className="btn">VIEW TOUR</a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="popText">
                <h2>Women Essence of Paradise </h2>
                <p className="txcolor">10 Days Solo Tour in Sri Lanka</p>
                <div className="icons">
                  <img src={taxi} alt="Transport Include" />
                  <img src={eye} alt="Sightseeing" />
                  <img src={map} alt="Locations" />
                  <img src={user} alt="English Speaking Chauffeur" />
                  <img src={bed} alt="Hotel Accommodation" />
                </div>

                <p>
                  Anuradhapura Ancient City, Sigiriya Rock Palace, Jeep Safari
                  Minneriya, Dambulla Rock Temple, Kandy
                </p>
              </div>
            </div>
          </Link>
        </div>

        <div className="popTour">
          <Link
          onClick={handleScroll}
            className="popTourLink"
            to={
              "/sri-lanka-tour-package/glimpse-of-ceylon/668b98c6e738bc132cce98ef"
            }
          >
            <div className="popThree">
              <div className="column">
                <div className="effect-3">
                  <div className="effect-img">
                    <img src={require("../img/pop1GLIMPSE.jpg")} alt="Image" />
                  </div>
                  <div className="effect-text">
                    <div className="effect-btn">
                      <a className="btn">VIEW TOUR</a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="popText">
                <h2>Glimpse of Ceylon </h2>
                <p className="txcolor">Discover Sri Lanka in 5 Days</p>
                <div className="icons">
                  <img src={taxi} alt="Transport Include" />
                  <img src={eye} alt="Sightseeing" />
                  <img src={map} alt="Locations" />
                  <img src={user} alt="English Speaking Chauffeur" />
                  <img src={bed} alt="Hotel Accommodation" />
                </div>

                <p>
                  Discover Sri Lanka in 5 Days Arrival, Anuradhapura Ancient
                  City, Sigiriya Rock Palace, Jeep Safari Minneriya, Dambulla
                  Rock Temple, Kandy
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Popular;
