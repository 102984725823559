import React, { useEffect, useState } from "react";
import "./heroSlide.scss";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import img_01 from "../../img/w__04.jpg";
import img_02 from "../../img/w__02.jpg";
import img_03 from "../../img/w__01.jpg";
import img_04 from "../../img/w__06.jpg";
import img_05 from "../../img/w__03.jpg";
import { IoLocationOutline } from "react-icons/io5";
import { ImFacebook2 } from "react-icons/im";
import { FaSquareInstagram } from "react-icons/fa6";
import { TfiYoutube } from "react-icons/tfi";
import { AiFillTikTok } from "react-icons/ai";

const HeroSlide = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState("forward");
  const totalSlides = 5;

const handleNext = () => {
  if (direction === "forward") {
    if (currentSlide === totalSlides - 1) {
      setDirection("backward");
      setCurrentSlide(currentSlide - 1);
    } else {
      setCurrentSlide((prev) => prev + 1);
    }
  } else {
    if (currentSlide === 0) {
      setDirection("forward");
      setCurrentSlide(currentSlide + 1);
    } else {
      setCurrentSlide((prev) => prev - 1);
    }
  }
};

const handlePrev = () => {
  if (direction === "backward") {
    if (currentSlide === 0) {
      setDirection("forward");
      setCurrentSlide(currentSlide + 1);
    } else {
      setCurrentSlide((prev) => prev - 1);
    }
  } else {
    if (currentSlide === totalSlides - 1) {
      setDirection("backward");
      setCurrentSlide(currentSlide - 1);
    } else {
      setCurrentSlide((prev) => prev + 1);
    }
  }
};

useEffect(() => {
  const interval = setInterval(() => {
    handleNext();
  }, 7000); // Change slide every 7 seconds
  return () => clearInterval(interval);
}, [currentSlide, direction]);


  return (
    <div className="__fw">
      <div className="hero_slide">
        <main style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
          <section
            style={{
              backdropFilter: "initial",
              backgroundImage: `url(${img_01})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              objectFit: "contain",
              width: "100%",
            }}
          >
            <div className="__mw">
              <div className="heading_wap">
                <div className="heading_wap_big">
                  <div className="head_top">
                    <p>
                      <em>
                        {" "}
                        <IoLocationOutline size={20} />
                      </em>{" "}
                      Kandy to Ella Train Ride
                    </p>
                  </div>
                  <h2>
                    <em className="spFont">Immerse yourself </em>
                    <br /> in vibrant cultures and breathtaking landscapes.
                  </h2>
                </div>
                <div className="heading_wap_smal">
                  {/* <p>
                    Get ready to embark on the{" "}
                    <strong>journey of a lifetime!</strong>
                    Our travel agency is dedicated to crafting unforgettable
                    experiences that will leave you with lifelong memories.
                  </p> */}
                </div>
              </div>
            </div>
            <div className="h_filter"></div>
          </section>

          <section
            style={{
              backgroundImage: `url(${img_02})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              objectFit: "contain",
              width: "100%",
            }}
          >
            <div className="__mw">
              <div className="heading_wap">
                <div className="heading_wap_big">
                  <div className="head_top">
                    <p>
                      <em>
                        {" "}
                        <IoLocationOutline size={20} />
                      </em>{" "}
                      Kandy Sri Dalada Maligawa
                    </p>
                  </div>
                  <h2>
                    <em className="spFont">Explore Sri Lanka’s </em>
                    <br /> ancient wonders – where history meets adventure.
                  </h2>
                </div>
                <div className="heading_wap_smal">
                  {/* <p>
                    Get ready to embark on the{" "}
                    <strong>journey of a lifetime!</strong>
                    Our travel agency is dedicated to crafting unforgettable
                    experiences that will leave you with lifelong memories.
                  </p> */}
                </div>
              </div>
            </div>
            <div className="h_filter"></div>
          </section>

          <section
            style={{
              backgroundImage: `url(${img_03})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              objectFit: "contain",
              width: "100%",
            }}
          >
            <div className="__mw">
              <div className="heading_wap">
                <div className="heading_wap_big">
                  <div className="head_top">
                    <p>
                      <em>
                        {" "}
                        <IoLocationOutline size={20} />
                      </em>{" "}
                      Bambarakanda Falls
                    </p>
                  </div>
                  <h2>
                    <em className="spFont">Experience the thrill</em> <br /> of
                    Sri Lanka’s wildlife and natural beauty.
                  </h2>
                </div>
                <div className="heading_wap_smal">
                  {/* <p>
                    Get ready to embark on the{" "}
                    <strong>journey of a lifetime!</strong>
                    Our travel agency is dedicated to crafting unforgettable
                    experiences that will leave you with lifelong memories.
                  </p> */}
                </div>
              </div>
            </div>
            <div className="h_filter"></div>
          </section>

          <section
            style={{
              backgroundImage: `url(${img_04})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              objectFit: "contain",
              width: "100%",
            }}
          >
            <div className="__mw">
              <div className="heading_wap">
                <div className="heading_wap_big">
                  <div className="head_top">
                    <p>
                      <em>
                        {" "}
                        <IoLocationOutline size={20} />
                      </em>{" "}
                      Mirissa Beach
                    </p>
                  </div>
                  <h2>
                    <em className="spFont">Unwind on pristine beaches,</em>{" "}
                    <br /> where the ocean meets serenity.
                  </h2>
                </div>
                <div className="heading_wap_smal">
                  {/* <p>
                    Get ready to embark on the{" "}
                    <strong>journey of a lifetime!</strong>
                    Our travel agency is dedicated to crafting unforgettable
                    experiences that will leave you with lifelong memories.
                  </p> */}
                </div>
              </div>
            </div>
            <div className="h_filter"></div>
          </section>

          <section
            style={{
              backgroundImage: `url(${img_05})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              objectFit: "contain",
              width: "100%",
            }}
          >
            <div className="__mw">
              <div className="heading_wap">
                <div className="heading_wap_big">
                  <div className="head_top">
                    <p>
                      <em>
                        {" "}
                        <IoLocationOutline size={20} />
                      </em>{" "}
                      Elephant Bathing Site
                    </p>
                  </div>
                  <h2>
                    <em className="spFont" >Create unforgettabl </em>
                    <br /> memories with every step in Sri Lanka.
                  </h2>
                </div>
                <div className="heading_wap_smal">
                  {/* <p>
                    Get ready to embark on the{" "}
                    <strong>journey of a lifetime!</strong>
                    Our travel agency is dedicated to crafting unforgettable
                    experiences that will leave you with lifelong memories.
                  </p> */}
                </div>
              </div>
            </div>
            <div className="h_filter"></div>
          </section>
        </main>

        <div className="__mw">
          <div className="social_icon">
            <div className="social_icon_wrp">
              <strong>Follow our socials</strong>
              <a href="https://www.facebook.com/seylontour/" target="_blank">
                <span>
                  <ImFacebook2 />
                </span>
              </a>
              <a href="https://www.instagram.com/seylontour" target="_blank">
                <span>
                  <FaSquareInstagram />
                </span>
              </a>
              <a
                href="https://www.youtube.com/channel/UCDyzsGOeLgAUaSis6k0E5Yg"
                target="_blank"
              >
                <span>
                  <TfiYoutube />
                </span>
              </a>
              <a href="https://www.tiktok.com/@seylontour" target="_blank">
                <span>
                  <AiFillTikTok />
                </span>
              </a>
            </div>
          </div>
        </div>

        <div className="nav-buttons">
          <button onClick={handlePrev} className="nav-button prev">
            <MdKeyboardArrowLeft size={30} />
          </button>
          <button onClick={handleNext} className="nav-button next">
            <MdKeyboardArrowRight size={30} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeroSlide;
