import React from "react";
import facebook from "../img/facebook-app-symbol.svg";
import youtube from "../img/youtube.svg";
import instagram from "../img/instagram.svg";
import mastercard from "../img/mastercard-brands.svg";
import visabrands from "../img/visa-brands.svg";
import PP from "../img/PP.svg";
import { Link } from "react-router-dom";
// import Western from "../img/Western_Union_logo.svg";

const Footer = () => {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="__fw" style={{ backgroundColor: "#1d1e1f" }}>
      <div className="__mw">
        <footer>
          {/* <center><img src="/static/images/" alt="seperator"/></center>  */}
          <div className="footLink">
            <div className="footSec">
              <h3>More Information</h3>
              <br />
              <ul>
                <li>
                  <Link onClick={scrollTop} to={"/about"}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link onClick={scrollTop} to={"/privacy-policy"}>
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link onClick={scrollTop} to={"/condition"}>
                    Terms & Condition
                  </Link>
                </li>
                {/* <li>
                <Link href="{% url 'condition' %}">
                  Cancellation & Refund Policy
                </Link>
              </li> */}
                <li>
                  <a
                    href="https://www.immigration.gov.lk/pages_e.php?id=64"
                    target="_blank"
                  >
                    Visa And Immigration
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.cbsl.gov.lk/rates-and-indicators/exchange-rates"
                    target="_blank"
                  >
                    Central Bank Of Sri Lanka
                  </a>
                </li>
                <li>
                  <Link onClick={scrollTop} to={"/responsible-tourism"}>
                    Responsible Tourism
                  </Link>
                </li>
                <li>
                  <Link onClick={scrollTop} to={"/faqs"}>
                    Essential Travel Information
                  </Link>
                </li>
              </ul>
            </div>
            {/* <div className="footSec">
            <h3>TOUR GUIDANCE</h3>
            <br />
            <ul>
              <li>
                <a
                  onClick={scrollTop}
                  href="http://srilanka.travel/index.php?route=common/home"
                >
                  Sri Lanka Tourism Promotion Bureau
                </a>
              </li>
              <li>
                <a onClick={scrollTop} href="http://www.sltda.lk/index.php">
                  Sri Lanka Tourism Development Authority
                </a>
              </li>
              <li>
                <a onClick={scrollTop} href="http://www.visitsrilanka.net/">
                  Sri Lanka Convention Bureau
                </a>
              </li>
              <li>
                <a onClick={scrollTop} href="http://culturaltriangle.com/">
                  Sri Lanka Cultural Triangle
                </a>
              </li>
              <li>
                <a
                  onClick={scrollTop}
                  href="http://srilanka.travel/lesserknown/index.php"
                >
                  Trailblazing in Sri Lanka
                </a>
              </li>
              <li>
                <a
                  onClick={scrollTop}
                  href="https://www.lonelyplanet.com/sri-lanka"
                >
                  Lonely Planet
                </a>
              </li>
              <li>
                <a href="{% url 'guidelines' %}">
                  Covid-19 Operational Guidelines
                </a>
              </li>
            </ul>
          </div> */}
            <div className="footSec">
              <h3>Tour Packages</h3>
              <br />
              <ul>
                <li>
                  <Link onClick={scrollTop} to={"/sri-lanka-tour-package"}>
                    VIP Tours
                  </Link>
                </li>
                <li>
                  <Link onClick={scrollTop} to={"/sri-lanka-tour-package"}>
                    Solo Tours
                  </Link>
                </li>
                {/* <li>
                <Link onClick={scrollTop} to={}>
                  Day Tours
                </Link>
              </li> */}
                <li>
                  <Link onClick={scrollTop} to={"/sri-lanka-tour-package"}>
                    Honeymoon Tours
                  </Link>
                </li>
                <li>
                  <Link onClick={scrollTop} to={"/sri-lanka-tour-package"}>
                    Study Tours
                  </Link>
                </li>
                <li>
                  <Link onClick={scrollTop} to={"/sri-lanka-tour-package"}>
                    Adventures Tours
                  </Link>
                </li>
                <li>
                  <Link onClick={scrollTop} to={"/sri-lanka-tour-package"}>
                    All Tours
                  </Link>
                </li>
              </ul>
            </div>

            <div className="footSec">
              <Link onClick={scrollTop} to="/group">
                <div className="logo-footSec">
                  <img src={require("../img/group.png")} alt="seylontour" />
                </div>
              </Link>

              <ul>
                <li>Maharagama, Colombo, Sri Lanka</li>
                <li>
                  <Link to={"/contact"}>info@seylontour.com</Link>
                </li>
                <br />

                <li>
                  <h3>Follow us on</h3>
                  <div className="social-logo">
                    <div className="s-logo">
                      <a
                        onClick={scrollTop}
                        target="blank"
                        href="https://www.facebook.com/seylontour"
                      >
                        <img src={facebook} alt="seylontour" />
                      </a>
                    </div>
                    <div className="s-logo">
                      <a
                        onClick={scrollTop}
                        target="blank"
                        href="https://www.youtube.com/channel/UCDyzsGOeLgAUaSis6k0E5Yg/featured"
                      >
                        <img src={youtube} alt="seylontour" />
                      </a>
                    </div>
                    <div className="s-logo">
                      <a
                        onClick={scrollTop}
                        target="blank"
                        href="https://www.instagram.com/seylontour/"
                      >
                        <img src={instagram} alt="seylontour" />
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className="trustpilot-widget"
                    data-locale="en-US"
                    data-template-id="5419b6a8b0d04a076446a9ad"
                    data-businessunit-id="628c79ebbdfd41093cd9837d"
                    data-without-reviews-preferred-string-id="2"
                  >
                    <div className="trustpilot-widget_in">
                      <a
                        href="https://www.trustpilot.com/review/seylontour.com"
                        rel="noopener"
                        target="blank"
                      >
                        <img
                          src={require("../img/trustpilot-logo.png")}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="paymant-container">
            <small>We Accept</small>

            <div className="paymant">
              <div className="pay-icon">
                <img src={mastercard} alt="mastercard" />
              </div>

              <div className="pay-icon">
                <img src={visabrands} alt="visa" />
              </div>

              <div className="pay-icon">
                <img src={PP} alt="papal" />
              </div>

              {/* <div className="pay-icon">
              <img src={Western} alt="Western Union" />
            </div> */}
            </div>
          </div>
        </footer>
        <div className="footer_CR">
          <p>
            Copyright © 2024 Leisurery Stays (PVT LTD). All Rights Reserved.{" "}
          </p>
          <a
            style={{ fontSize: "12px", marginLeft: "20px" }}
            href="https://redesigno.com/"
          >
            Provide by ReDesigno.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
