import React, { useEffect, useState } from "react";
import "./blog.scss";

import BlogPost from "../../components/blogPost/BlogPost";

const Blog = () => {
  

  return (
    <div className="__fw">
      <div className="__mw">
        <div className="pag_headign">
          <small className="subTag">Blog & news</small>
          <h1>
            <em className="spFont">The Journey Of A Lifetime:</em> <br /> Create
            Unforgettable Memories
          </h1>
        </div>
        <BlogPost/>
        
      </div>
    </div>
  );
};

export default Blog;
