import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const BlogPost = () => {
  const [blogData, setBlogData] = useState([]);
  const [categories, setCategories] = useState([]); // Holds selected categories
  const [loading, setLoading] = useState(true);

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const getBlogsAndCategories = async () => {
      try {
        // Fetch blogs
        const blogsResponse = await axios.get("/getBlog");
        // Sort blogs by date in descending order (newest first)
        const sortedBlogs = blogsResponse.data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setBlogData(sortedBlogs);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    getBlogsAndCategories();
  }, []);

  const handleCategoryClick = (category) => {
    // Toggle category in the list of selected categories
    if (categories.includes(category)) {
      setCategories(categories.filter((cat) => cat !== category));
    } else {
      setCategories([...categories, category]);
    }
  };

  // Filter blogs based on selected categories
  const filteredBlogs = categories.length
    ? blogData.filter((item) => categories.includes(item.category))
    : blogData;

  return (
    <div className="blog">
      <div className="blog_filter" id="a">
        <h2>Categories</h2>
        {/* Clickable categories */}
        <div className="category_labels">
          {[
            "Destination Guides",
            "Cultural Experiences",
            "Adventure and Nature",
            "Travel Tips",
            "Culinary Journeys",
            "Historical Insights",
            "Accommodation Reviews",
            "Travel Stories",
            "Sustainable Tourism",
            "Events and Festivals",
          ].map((category) => (
            <div
              key={category}
              className={`category_label ${
                categories.includes(category) ? "selected" : ""
              }`}
              onClick={() => handleCategoryClick(category)}
              style={{
                cursor: "pointer",
                padding: "5px 10px",
                margin: "5px",
                border: "none",
                borderRadius: "5px",
                backgroundColor: categories.includes(category)
                  ? "#f0f0f0"
                  : "white",
              }}
            >
              {category}
            </div>
          ))}
        </div>
      </div>
      <div className="blog_list" id="b">
        {loading ? (
          <p>Loading blogs...</p>
        ) : (
          filteredBlogs.map((item, i) => (
            <Link onClick={scrollTop} to={`/blog/${item._id}`} key={i}>
              <div className="blog_card">
                <img
                  src={`${process.env.REACT_APP_BLOG_IMAGE_UPLOAD_LINK}/${item.blogPhotos[0]}`}
                  alt=""
                />
                <div className="blog_info">
                  <p style={{ color: "white", fontSize: "12px" }}>
                    {item.date}
                  </p>
                  <p>{item.category} | Admin </p>
                  <h2>{item.heading}</h2>
                  <span>Read More</span>
                </div>
              </div>
            </Link>
          ))
        )}
      </div>
    </div>
  );
};

export default BlogPost;
