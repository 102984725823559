import React from 'react'
import './group.scss'

const GroupPage = () => {
  return (
    <div className="__fw" style={{ backgroundColor: "rgb(29, 30, 31)" }}>
      <div className="__mw">
        <div className="pag_headign">
          <small className="subTag">Who We Are</small>
          <h1 style={{ color: "white" }}>
            <em className="spFont">Your Trusted Partner,</em> <br /> Explore Our
            Group of Companies and Brands
          </h1>
        </div>
        <div className="grop_wrp">
          <div className="g_onfo">
            <p>
              Combining the expertise of our companies and the power of our
              brands, we offer unparalleled solutions across various industries.
              Our group stands as a symbol of reliability, trust, and collective
              strength.
            </p>
          </div>
          <div className="group">
            <div className="com_card">
              <h2>LeisureryStays (Pvt Ltd) Company </h2>
            </div>
            <div className="com_card">
              <h2>GARI Tours (Pvt) Ltd Company </h2>
            </div>
            <div className="com_card">
              <h2>Seylontour</h2>
            </div>
            <div className="com_card">
              <h2>Leisurery.com</h2>
            </div>
            <div className="com_card">
              <h2>ReDesigno</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupPage
