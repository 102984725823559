import React, { useEffect, useCallback, useState, memo } from "react";

const getDays = (time) => Math.floor(time / (1000 * 60 * 60 * 24));
const getHours = (time) => Math.floor((time / (1000 * 60 * 60)) % 24);
const getMinutes = (time) => Math.floor((time / 1000 / 60) % 60);
const getSeconds = (time) => Math.floor((time / 1000) % 60);

const timeSuffix = {
  0: "d",
  1: "h",
  2: "m",
  3: "s",
};

// Function to calculate the next deadline (5th of every month, 7 days before)
const calculateDeadline = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();

  // Calculate the 5th of the next month
  const next5th = new Date(year, month + 1, 5);
  const deadline = new Date(next5th.getTime() - 7 * 24 * 60 * 60 * 1000); // Subtract 7 days

  return deadline;
};

const Clock = () => {
  const [time, setTime] = useState([0, 0, 0, 0]);

  const leading0 = (num) => {
    return num < 10 ? "0" + num : num;
  };

  const getTimeUntil = useCallback((deadline) => {
    const time = deadline.getTime() - new Date().getTime();
    if (time < 0) {
      setTime([
        leading0(getDays(0)),
        leading0(getHours(0)),
        leading0(getMinutes(0)),
        leading0(getSeconds(0)),
      ]);
    } else {
      setTime([
        leading0(getDays(time)),
        leading0(getHours(time)),
        leading0(getMinutes(time)),
        leading0(getSeconds(time)),
      ]);
    }
  }, []);

  useEffect(() => {
    const deadline = calculateDeadline();
    const timerRef = setInterval(() => getTimeUntil(deadline), 1000);

    return () => {
      clearInterval(timerRef);
    };
  }, [getTimeUntil]);

  return (
    <div className="Clock-container">
      {time.map((timeStamp, index) => (
        <React.Fragment key={index}>
          <div>
            {timeStamp} {timeSuffix[index]}
          </div>
          {index < time.length - 1 && <span>|</span>}
        </React.Fragment>
      ))}
    </div>
  );
};

export default memo(Clock);
