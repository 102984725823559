import React from 'react'
import "./whtWe.scss";
import wallImg from "../../img/meme.jpg";
import { MdOutlineStarPurple500 } from "react-icons/md";

const WhyWe = () => {
  return (
    <>
      <div className="pag_headign">
        <small className="subTag">Why choose us</small>
        <h1>
          {" "}
          <em className='spFont' >Unforgettable Journeys:</em> Where Memories Are Made
        </h1>
      </div>
      <div
        className="why_img"
        style={{
          backgroundImage: `url(${wallImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          objectFit: "contain",
        }}
      >
        <div className="comet">
          <p>
            I was initially hesitant to book a trip with a new agency, but you
            completely surpassed my expectations. The staff was incredibly
            friendly and helpful, assisting me in planning an unforgettable trip
            to Sri Lnaka. I would highly recommend your agency to anyone seeking
            a fun and adventurous getaway!
          </p>
          <div className="name">
            <p>
              Christina <br /> <em>Tourist</em>{" "}
            </p>
            <span>
              <MdOutlineStarPurple500 className="_icon" />
              <MdOutlineStarPurple500 className="_icon" />
              <MdOutlineStarPurple500 className="_icon" />
              <MdOutlineStarPurple500 className="_icon" />
              <MdOutlineStarPurple500 className="_icon" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyWe
