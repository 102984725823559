import React, { useEffect, useState } from "react";
import products from "./products.js";
import "./carousel.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import { IoLocationOutline } from "react-icons/io5";
import { IoMdTime } from "react-icons/io";
import { GoPeople } from "react-icons/go";
import axios from "axios";
import { BiCategory } from "react-icons/bi";
import { MdOutlineStar } from "react-icons/md";
import { IoIosClose } from "react-icons/io";
import "./carousel.scss";

const CityCarousel = () => {
  const [data, setData] = useState([]);
  const [types, setTypes] = useState("");
  const [category, setCategory] = useState("");
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  const handleOpen = () => {
    setShow(!show);
  };

  const onEditClick = () => {
    window.scrollTo(0, 0);
  };

  const getData = async () => {
    try {
      const response = await axios.get(`/get_all_tours`, {
        params: { types, category },
        headers: {
          "Content-Type": "application/json",
        },
      });
      setData(response.data);
      setError(""); // Clear any previous errors
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError("No matching tours found. Try something else.");
      } else {
        setError("An error occurred. Please try again later.");
      }
      setData([]); // Clear data if there's an error
    }
  };

  useEffect(() => {
    getData();
  }, [category, types]);

  const options = {
    loop: true,
    margin: 30,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      600: {
        items: 2,
        nav: false,
      },
      1000: {
        items: 3,
        nav: true,
        loop: false,
      },
    },
  };

  return (

    
      <div className="carousel-wrapper owl-carousel owl-theme owl-loaded">
        <OwlCarousel className="owl-theme" {...options}>
         

          {data.map((item, i) => (
          <div className="item" key={i}>
            <Link
              onClick={handleScroll}
              to={`/sri-lanka-tour-package/${item.heading
                .toLowerCase()
                .replace(/ /g, "-")}/${item._id}`}
            >
              <div className="card_wrap">
                <div className="card_img">
                  <img
                    src={`${process.env.REACT_APP_TOUR_UPLOAD_LINK}/${item.tourPhotos[0]}`}
                    alt={item.heading}
                  />
                </div>
                {item.discount && (
                  <div className="offer_amount">
                    <span>{item.discount}% OFF</span>
                  </div>
                )}
              </div>
              <div className="card_info">
                <div className="card_info_head">
                  <h3>{item.heading}</h3>
                  <p>{item.description}</p>
                </div>
                <div className="card_info_info">
                  <hr />
                </div>
                <div className="card_info_price">
                  <div className="card_info_price_item_left">
                    <span>
                      <BiCategory color="#4e8fee" fontSize={18} /> {item.types}
                    </span>
                    <span>
                      <IoMdTime color="#4e8fee" fontSize={18} /> {item.category}
                    </span>
                    <span>
                      <GoPeople color="#4e8fee" fontSize={18} /> 1-5 People
                    </span>
                  </div>
                  <div className="card_info_price_item_right">
                    {item.price ? (
                      <>
                        <p>$3100</p>
                        <h2>
                          $ {item.price} <em>P/P</em>{" "}
                        </h2>
                      </>
                    ) : (
                      <h4>
                        Price On <br /> Request
                      </h4>
                    )}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
        </OwlCarousel>
      </div>

  );
};

export default CityCarousel;
